import {
  Card,
  Chip,
  EmptyState,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  reduceDecimals,
  translate,
  useClientSort,
} from '@orbiapp/components';

import { MetricBreakdownTableCell } from '../../../../../components/insights';
import { CONTACT_CUSTOMER_SUCCESS_URL } from '../../../../../constants';
import { TableInsightsItems } from '../../../../../models';
import {
  InsightsTimeSeriesSelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../../../../store';
import { getProductLabelTx } from '../../../../../utils';

const TABLE_COLUMN_WIDTHS = {
  label: 200,
  type: 100,
  engagementRate: 150,
  engagements: 150,
  views: 150,
  impressions: 150,
};

function renderTableRow(item: TableInsightsItems, index: number) {
  const label =
    'label' in item
      ? item.label
      : translate('label.select-products-menu.total');

  return (
    <TableRow key={`metrics-breakdown-table-row-${index}`}>
      <TableCell width={TABLE_COLUMN_WIDTHS.label} text={label} />

      <TableCell width={TABLE_COLUMN_WIDTHS.type}>
        <Chip variant="secondary" tx={getProductLabelTx(item.type)} />
      </TableCell>

      <MetricBreakdownTableCell
        value={reduceDecimals(item.values.engagementRate ?? 0)}
        comparedValue={reduceDecimals(item.valuesCompared?.engagementRate)}
        unit="%"
        width={TABLE_COLUMN_WIDTHS.engagementRate}
      />

      <MetricBreakdownTableCell
        value={item.values.engagement ?? 0}
        comparedValue={item.valuesCompared?.engagement}
        width={TABLE_COLUMN_WIDTHS.engagements}
      />

      <MetricBreakdownTableCell
        value={item.values.views ?? 0}
        comparedValue={item.valuesCompared?.views}
        width={TABLE_COLUMN_WIDTHS.views}
      />

      <MetricBreakdownTableCell
        value={item.values.impressions ?? 0}
        comparedValue={item.valuesCompared?.impressions}
        width={TABLE_COLUMN_WIDTHS.impressions}
      />
    </TableRow>
  );
}

const columnNames = {
  label: 'label',
  engagementRate: 'values.engagementRate',
  engagement: 'values.engagement',
  views: 'values.views',
  impressions: 'values.impressions',
};

function MetricsBreakdownTable() {
  const tableEntries = useSelector(
    InsightsTimeSeriesSelector.selectTableEntries,
  );
  const insightsTimeSeriesStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const { items, sortOrder, orderBy, onSort } = useClientSort(tableEntries, {
    defaultOrderBy: columnNames.label,
    defaultSortOrder: 'desc',
  });

  if (insightsTier !== 't2') {
    return (
      <EmptyState
        buttonTx="label.connect.contact-sales"
        titleTx="label.fullbreakdown-locked"
        href={CONTACT_CUSTOMER_SUCCESS_URL}
      />
    );
  }

  if (insightsTimeSeriesStatus === 'pending') {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.metrics-breakdown.item" />
            <TableHead tx="label.metrics-breakdown.type" />
            <TableHead
              tx="label.metrics-breakdown.engagement-rate"
              tooltip={{
                placement: 'top',
                titleTx: 'label.insights.engagement-percent-description',
              }}
            />
            <TableHead
              tx="label.metrics-breakdown.engagement"
              tooltip={{
                placement: 'top',
                titleTx: 'label.insights.total-engagements-description',
              }}
            />
            <TableHead
              tx="label.metrics-breakdown.views"
              tooltip={{
                placement: 'top',
                titleTx: 'label.insights.total-views-description',
              }}
            />
            <TableHead
              tx="label.metrics-breakdown.impressions"
              tooltip={{
                placement: 'top',
                titleTx: 'label.insights.total-impressions-description',
              }}
            />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={5}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader
        onSort={onSort}
        orderBy={orderBy}
        sortableColumns={Object.values(columnNames)}
        sortOrder={sortOrder}
      >
        <TableRow>
          <TableHead
            tx="label.metrics-breakdown.item"
            name={columnNames.label}
          />
          <TableHead tx="label.metrics-breakdown.type" />
          <TableHead
            tx="label.metrics-breakdown.engagement-rate"
            name={columnNames.engagementRate}
            tooltip={{
              placement: 'top',
              titleTx: 'label.insights.engagement-percent-description',
            }}
          />
          <TableHead
            tx="label.metrics-breakdown.engagement"
            name={columnNames.engagement}
            tooltip={{
              placement: 'top',
              titleTx: 'label.insights.total-engagements-description',
            }}
          />
          <TableHead
            tx="label.metrics-breakdown.views"
            name={columnNames.views}
            tooltip={{
              placement: 'top',
              titleTx: 'label.insights.total-views-description',
            }}
          />
          <TableHead
            tx="label.metrics-breakdown.impressions"
            name={columnNames.impressions}
            tooltip={{
              placement: 'top',
              titleTx: 'label.insights.total-impressions-description',
            }}
          />
        </TableRow>
      </TableHeader>
      <TableBody>{items.map(renderTableRow)}</TableBody>
    </Table>
  );
}

export function MetricsBreakdown() {
  return (
    <Card>
      <Text variant="bodyMdBold" tx="label.metrics-breakdown.title" />

      <MetricsBreakdownTable />
    </Card>
  );
}
