import { joiResolver } from '@hookform/resolvers/joi';
import { FormBox, InnerContentContainerAlt } from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateJobForm, CreateJobFormValidation } from '../../../../models';
import { OfficeLocationsSelector, useSelector } from '../../../../store';
import { AdditionalInformation } from './additional-information';
import { Budget } from './budget';
import { ContactDetails } from './contact-details';
import { JobDetails } from './job-details';
import { JobFormHeader } from './job-form-header';
import { Locations } from './locations';
import { TargetGroup } from './target-group';
import { TypeOfPositions } from './type-of-positions';

function JobFormContent(props: { defaultValues: CreateJobForm }) {
  const { defaultValues } = props;

  const officeLocationKeys = useSelector(
    OfficeLocationsSelector.selectOfficeLocationKeys,
  );

  const formMethods = useForm<CreateJobForm>({
    defaultValues,
    resolver: joiResolver(CreateJobFormValidation({ officeLocationKeys })),
  });

  return (
    <FormProvider {...formMethods}>
      <InnerContentContainerAlt>
        <JobFormHeader />

        <FormBox>
          <JobDetails />

          <AdditionalInformation />

          <TargetGroup />

          <Locations />

          <TypeOfPositions />

          <ContactDetails />

          <Budget />
        </FormBox>
      </InnerContentContainerAlt>
    </FormProvider>
  );
}

export function JobForm(props: { defaultValues: CreateJobForm }) {
  const { defaultValues } = props;

  return <JobFormContent defaultValues={defaultValues} />;
}
