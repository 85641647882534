import {
  Account,
  QUERY_CUSTOM_TOKEN_KEY,
  QUERY_WORKSPACE_KEY,
  UpdateWorkspace,
  WorkspacesSessionStorage,
  constructQueryURL,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { STUDENT_DASHBOARD_URL } from '../../config';
import {
  CreateAccountByInvitationParams,
  UpdateAccountForm,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import {
  authActions,
  getAuthAccountThunk,
  signInThunk,
  signOutThunk,
} from '../auth';
import { getCompanyThunk } from '../company';
import { getFeatureFlagsThunk } from '../feature-flags';
import { ThunkApiConfig } from '../store.types';

function getSwitchWorkspaceBaseURL(emulateUserKey?: string) {
  if (!emulateUserKey) {
    return STUDENT_DASHBOARD_URL;
  }

  return [STUDENT_DASHBOARD_URL, 'emulate-user', emulateUserKey].join('/');
}

export const getAccountThunk = createAsyncThunk<
  Account,
  undefined,
  ThunkApiConfig
>('account/get-account', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.account.getAccount, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  thunkAPI.dispatch(signOutThunk());
  return thunkAPI.rejectWithValue(res);
});

export const createAccountByInvitationThunk = createAsyncThunk<
  string,
  CreateAccountByInvitationParams & { email: string; hasCompany: boolean },
  ThunkApiConfig
>('account/create-account-by-invitation', async (params, thunkAPI) => {
  const { email, hasCompany, ...rest } = params;

  const res = await OrbiApi.call(v1.account.createAccountByInvitation, rest);

  await thunkAPI.dispatch(
    signInThunk({
      email: params.email,
      password: params.password,
    }),
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const updateAccountByInvitationThunk = createAsyncThunk<
  void,
  {
    companyInvitationKey: string;
    email: string;
    password: string;
    hasCompany: boolean;
  },
  ThunkApiConfig
>('account/update-account-by-invitation', async (params, thunkAPI) => {
  await thunkAPI.dispatch(
    signInThunk({
      email: params.email,
      password: params.password,
    }),
  );

  if (!params.hasCompany) return;

  const res = await OrbiApi.call(v1.account.updateAccountByInvitation, {
    companyInvitationKey: params.companyInvitationKey,
  });

  if (res.kind === 'ok') {
    WorkspacesSessionStorage.endSession();
    await thunkAPI.dispatch(getAccountThunk());
    await thunkAPI.dispatch(getFeatureFlagsThunk());
    await thunkAPI.dispatch(getAuthAccountThunk());
    await thunkAPI.dispatch(getCompanyThunk());
    return;
  }

  return thunkAPI.rejectWithValue(res);
});

export const updateAccountThunk = createAsyncThunk<
  void,
  UpdateAccountForm,
  ThunkApiConfig
>('account/update-account', async (updateAccountParams, thunkAPI) => {
  const res = await OrbiApi.call(v1.account.updateAccount, updateAccountParams);

  if (res.kind === 'ok') {
    return;
  }

  return thunkAPI.rejectWithValue(res);
});

export const updateWorkspaceThunk = createAsyncThunk<
  void,
  UpdateWorkspace,
  ThunkApiConfig
>('account/update-workspace', async (updateWorkspaceParams, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.account.updateWorkspace,
    updateWorkspaceParams,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }
});

export const switchWorkspaceThunk = createAsyncThunk<
  void,
  UpdateWorkspace & { emulateUserKey?: string },
  ThunkApiConfig
>('account/switch-workspace', async (switchWorkspaceParams, thunkAPI) => {
  const customTokenRes = await OrbiApi.call(
    v1.auth.getCustomSignInToken,
    undefined,
  );

  if (customTokenRes.kind !== 'ok') {
    return thunkAPI.rejectWithValue(customTokenRes);
  }

  switch (switchWorkspaceParams.type) {
    case 'department':
      window.location.href = constructQueryURL(
        getSwitchWorkspaceBaseURL(switchWorkspaceParams.emulateUserKey),
        {
          [QUERY_CUSTOM_TOKEN_KEY]: customTokenRes.data,
          [QUERY_WORKSPACE_KEY]: switchWorkspaceParams.departmentKey,
        },
      );
      break;

    case 'company':
      WorkspacesSessionStorage.startSession(switchWorkspaceParams.companyKey);

      await thunkAPI.dispatch(getAccountThunk());
      await thunkAPI.dispatch(getFeatureFlagsThunk());
      await thunkAPI.dispatch(getAuthAccountThunk());
      await thunkAPI.dispatch(getCompanyThunk());
      thunkAPI.dispatch(
        authActions.setAuthState({
          accountVerified: true,
          authenticated: true,
          initializing: false,
        }),
      );
      break;
  }

  return;
});
