import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  Company,
  DeleteLocationErrorResponse,
  OfficeLocation,
  UpdateCompanyForm,
  UpdatedCompany,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { updateCompanyMapper } from './company.mappers';

export const getCompany = async (): Promise<
  GeneralApiResultWithData<Company>
> => {
  const res = await apisauce.get<Company, any>('/v2/company');

  if (!res.ok) {
    Logger.warning('getCompany', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getCompany', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCompany = async (
  updateCompany: UpdateCompanyForm,
): Promise<GeneralApiResultWithData<UpdatedCompany>> => {
  const res = await apisauce.patch<UpdatedCompany, any>(
    '/v2/company',
    updateCompanyMapper(updateCompany),
  );

  if (!res.ok) {
    Logger.warning('updateCompany', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('updateCompany', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const createOfficeLocation = async (
  createOfficeLocation: OfficeLocation,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ officeLocationKey: string }, any>(
    '/v2/company/office-locations',
    {
      address: createOfficeLocation.address,
      label: createOfficeLocation.label,
      latitude: createOfficeLocation.latitude,
      longitude: createOfficeLocation.longitude,
      placeId: createOfficeLocation.placeId,
    },
  );

  if (!res.ok) {
    Logger.warning('createOfficeLocation', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.officeLocationKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createOfficeLocation', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getOfficeLocations = async (): Promise<
  GeneralApiResultWithData<OfficeLocation[]>
> => {
  const res = await apisauce.get<OfficeLocation[], any>(
    '/v2/company/office-locations',
  );

  if (!res.ok) {
    Logger.warning('getOfficeLocations', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getOfficeLocations', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateOfficeLocation = async (
  updateOfficeLocationParams: OfficeLocation,
): Promise<GeneralApiResult> => {
  const { officeLocationKey, ...rest } = updateOfficeLocationParams;

  const res = await apisauce.patch<undefined, any>(
    `/v2/company/office-locations/${officeLocationKey}`,
    rest,
  );

  if (!res.ok) {
    Logger.warning('updateOfficeLocation', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteOfficeLocation = async (
  officeLocationKey: string,
): Promise<GeneralApiResult<DeleteLocationErrorResponse>> => {
  const res = await apisauce.delete<undefined, DeleteLocationErrorResponse>(
    `v2/company/office-locations/${officeLocationKey}`,
  );

  if (!res.ok) {
    Logger.warning('deleteOfficeLocation', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
