import {
  Account,
  ChipProps,
  EditMeta,
  Time,
  translate,
} from '@orbiapp/components';

import {
  BudgetType,
  CreateJobForm,
  EmploymentType,
  JOB_TITLE_MAX_LENGTH,
  Job,
  JobDraft,
  OfficeLocation,
  PartialJob,
  SubjectArea,
  UpdateJobDraftForm,
  UpdateJobForm,
} from '../models';
import { sortAndMapLocations } from './location';

export function getJobBudgetType(partialJob: {
  dailyBudget: number | null;
  totalBudget: number | null;
}): BudgetType {
  const { dailyBudget, totalBudget } = partialJob;

  if ((!dailyBudget && !totalBudget) || (dailyBudget && totalBudget)) {
    return 'both';
  }

  if (dailyBudget) {
    return 'daily';
  }

  return 'total';
}

export function getCreateJobFormDefaultValues(
  jobDraft: JobDraft,
): CreateJobForm {
  const locationData: CreateJobForm['locationData'] = {
    workModel: jobDraft.locationData.workModel ?? 'onsite',
    officeLocations: sortAndMapLocations(jobDraft.locationData.officeLocations),
  };

  const now = new Date().setHours(0, 0, 0, 0);
  const startDate = jobDraft.startDate ?? now + 1 * Time.Day;
  const endDate = jobDraft.endDate ?? startDate + 2 * Time.Week;

  return {
    contactEmail: jobDraft.contactEmail ?? '',
    contactName: jobDraft.contactName ?? '',
    contactPhone: jobDraft.contactPhone ?? '',
    coverImage: jobDraft.coverImageBase64 ?? null,
    budgetData: {
      dailyBudget: jobDraft.budgetData.dailyBudget,
      budgetType: getJobBudgetType({
        totalBudget: jobDraft.budgetData.totalBudget,
        dailyBudget: jobDraft.budgetData.dailyBudget,
      }),
      totalBudget: jobDraft.budgetData.totalBudget,
    },
    description: jobDraft.description ?? '',
    employmentType: jobDraft.employmentType ?? 'fulltime',
    endDate,
    jobDraftKey: jobDraft.jobDraftKey ?? null,
    locationData,
    originalJobUrl: jobDraft.originalJobUrl ?? '',
    qualifications: jobDraft.qualifications ?? [],
    startDate,
    studyLevels: jobDraft.studyLevels ?? [],
    subjectAreaKeys:
      jobDraft.subjectAreas?.map((subjectArea) => subjectArea.subjectAreaKey) ??
      [],
    title: jobDraft.title ?? '',
  };
}

export function getUpdateJobDraftValuesFromJob(
  job: Job,
  jobDraftKey: string,
): UpdateJobDraftForm {
  const locationData: UpdateJobDraftForm['locationData'] = {
    officeLocations:
      job.locationData.officeLocations
        ?.sort((a, b) => a.index - b.index)
        ?.map((officeLocation, index) => ({
          index,
          officeLocationKey: officeLocation.officeLocationKey,
        })) ?? null,
    workModel: job.locationData.workModel,
  };

  if (
    locationData.workModel !== 'remote' &&
    !locationData.officeLocations?.length
  ) {
    locationData.workModel = 'remote';
  }

  return {
    budgetData: {
      dailyBudget: job.budgetData.dailyBudget,
      totalBudget: job.budgetData.totalBudget,
    },
    contactEmail: job.contactEmail,
    contactName: job.contactName,
    contactPhone: job.contactPhone,
    coverImage: job.coverImageBase64,
    description: job.description,
    employmentType: job.employmentType,
    endDate: job.endDate > Date.now() ? job.endDate : null,
    jobDraftKey,
    locationData,
    originalJobUrl: job.originalJobUrl,
    qualifications: job.qualifications,
    startDate: job.startDate,
    studyLevels: job.studyLevels,
    subjectAreaKeys: job.subjectAreas.map(
      (subjectArea) => subjectArea.subjectAreaKey,
    ),
    title: translate('label.general.copy-of', {
      name: job.title,
    }).substring(0, JOB_TITLE_MAX_LENGTH),
  };
}

export function getJobEditMeta(
  account: Account,
  currentMeta?: EditMeta,
): EditMeta {
  const now = Date.now();

  return {
    createdAt: currentMeta?.createdAt ?? now,
    updatedAt: now,
    createdBy: currentMeta?.createdBy ?? {
      firstName: account.firstName,
      lastName: account.lastName,
      userKey: account.userKey,
      profilePicture: null,
    },
    updatedBy: {
      firstName: account.firstName,
      lastName: account.lastName,
      userKey: account.userKey,
      profilePicture: null,
    },
  };
}

export function getUpdateJobReturnValue(
  updateJobForm: UpdateJobForm,
  currentState: Job,
  officeLocations: OfficeLocation[],
  subjectAreas: SubjectArea[],
  account: Account | null,
): Job {
  const officeLocationsMap = officeLocations.reduce((acc, officeLocation) => {
    acc[officeLocation.officeLocationKey] = officeLocation;

    return acc;
  }, {} as Record<string, OfficeLocation>);

  const budgetData = updateJobForm.budgetData
    ? {
        totalBudget: updateJobForm.budgetData.totalBudget,
        dailyBudget: updateJobForm.budgetData.dailyBudget,
        budgetType: getJobBudgetType(updateJobForm.budgetData),
      }
    : currentState.budgetData;

  const subjectAreasMap = new Map(
    subjectAreas.map((subjectArea) => [
      subjectArea.subjectAreaKey,
      subjectArea,
    ]),
  );

  const job: Job = {
    budgetData,
    isManuallyClosed: currentState.isManuallyClosed,
    title: updateJobForm.title ?? currentState.title,
    contactEmail: updateJobForm.contactEmail ?? currentState.contactEmail,
    contactName: updateJobForm.contactName ?? currentState.contactName,
    contactPhone: updateJobForm.contactPhone ?? currentState.contactPhone,
    coverImage: currentState.coverImage,
    coverImageBase64: updateJobForm.coverImage ?? currentState.coverImageBase64,
    dailyBudgetReachedAt: currentState.dailyBudgetReachedAt,
    description: updateJobForm.description ?? currentState.description,
    editMeta: account
      ? getJobEditMeta(account, currentState.editMeta)
      : { ...currentState.editMeta, updatedAt: Date.now() },
    employmentType: updateJobForm.employmentType ?? currentState.employmentType,
    endDate: updateJobForm.endDate ?? currentState.endDate,
    jobKey: currentState.jobKey,
    locationData: {
      workModel:
        updateJobForm.locationData?.workModel ??
        currentState.locationData.workModel,
      officeLocations: updateJobForm.locationData?.officeLocations
        ? updateJobForm.locationData?.officeLocations.map((officeLocation) => {
            return {
              ...officeLocationsMap[officeLocation.officeLocationKey],
              index: officeLocation.index,
              officeLocationKey: officeLocation.officeLocationKey,
            };
          })
        : currentState.locationData.officeLocations,
    },
    originalJobUrl: updateJobForm.originalJobUrl ?? currentState.originalJobUrl,
    qualifications: updateJobForm.qualifications ?? currentState.qualifications,
    startDate: updateJobForm.startDate ?? currentState.startDate,
    studyLevels: updateJobForm.studyLevels ?? currentState.studyLevels,
    subjectAreas:
      updateJobForm.subjectAreaKeys?.map(
        (subjectAreaKey) => subjectAreasMap.get(subjectAreaKey)!,
      ) ?? currentState.subjectAreas,
    totalBudgetReachedAt: currentState.totalBudgetReachedAt,
    stats: currentState.stats,
  };

  return job;
}

export function getEmploymentTypeChipProps(type: EmploymentType): ChipProps {
  switch (type) {
    case 'apprenticeship':
      return {
        backgroundColor: 'apprenticeshipChipBackground',
        borderColor: 'apprenticeshipChipBorder',
        labelColor: 'apprenticeshipChipLabel',
        tx: 'label.employment-types.apprenticeship',
      };

    case 'fulltime':
      return {
        backgroundColor: 'fulltimeChipBackground',
        borderColor: 'fulltimeChipBorder',
        labelColor: 'fulltimeChipLabel',
        tx: 'label.employment-types.fulltime',
      };

    case 'internship':
      return {
        backgroundColor: 'internshipChipBackground',
        borderColor: 'internshipChipBorder',
        labelColor: 'internshipChipLabel',
        tx: 'label.employment-types.internship',
      };

    case 'parttime':
      return {
        backgroundColor: 'parttimeChipBackground',
        borderColor: 'parttimeChipBorder',
        labelColor: 'parttimeChipLabel',
        tx: 'label.employment-types.parttime',
      };

    case 'thesis':
      return {
        backgroundColor: 'thesisChipBackground',
        borderColor: 'thesisChipBorder',
        labelColor: 'thesisChipLabel',
        tx: 'label.employment-types.thesis',
      };

    case 'summer_job':
      return {
        backgroundColor: 'seasonalChipBackground',
        borderColor: 'seasonalChipBorder',
        labelColor: 'seasonalChipLabel',
        tx: 'label.employment-types.summer-job',
      };
  }
}

export function jobToPartialJobMapper(job: Job): PartialJob {
  return {
    dailyBudgetReachedAt: job.dailyBudgetReachedAt,
    editMeta: job.editMeta,
    employmentType: job.employmentType,
    endDate: job.endDate,
    isManuallyClosed: job.isManuallyClosed,
    jobKey: job.jobKey,
    startDate: job.startDate,
    title: job.title,
    totalBudgetReachedAt: job.totalBudgetReachedAt,
  };
}
