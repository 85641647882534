import { GeneralApiResultWithData } from '@orbiapp/components';

import { PerkCategory } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { PerkCategoryDto } from './perk-categories.dto';
import { perkCategoryMapper } from './perk-categories.mappers';

export const getPerkCategories = async (): Promise<
  GeneralApiResultWithData<PerkCategory[]>
> => {
  const res = await apisauce.get<PerkCategoryDto[], any>('/v2/perk-categories');

  if (!res.ok) {
    Logger.warning('getPerkCategories', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(perkCategoryMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getPerkCategories', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
