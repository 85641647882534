import { translate } from '@orbiapp/components';

import {
  GetTimeBasedInsights,
  InsightsItem,
  LiveInsights,
  TimeBasedInsights,
} from '../../../../../models';
import {
  GetTimeSeriesInsightsDto,
  InsightsItemDto,
  LiveInsightsDto,
  TimeBasedInsightsDto,
} from './insights.dto';

export function getInsightsLiveDataMapper(
  liveInsightsDto: LiveInsightsDto,
): LiveInsights {
  return {
    companyAds: {
      live: liveInsightsDto.companyAds.live,
      past: liveInsightsDto.companyAds.past,
      scheduled: liveInsightsDto.companyAds.scheduled,
    },
    companyProfiles: {
      draft: liveInsightsDto.companyProfiles.draft,
      live: liveInsightsDto.companyProfiles.live,
    },
    jobs: {
      closed: liveInsightsDto.jobs.closed,
      draft: liveInsightsDto.jobs.draft,
      live: liveInsightsDto.jobs.live,
      scheduled: liveInsightsDto.jobs.scheduled,
    },
    talentPool: {
      userCount: liveInsightsDto.talentPool.userCount,
      users: liveInsightsDto.talentPool.users.map((talentPoolUserDto) => ({
        companyQr: talentPoolUserDto.companyQr,
        createdAt: talentPoolUserDto.createdAt,
        email: talentPoolUserDto.email,
        firstName: talentPoolUserDto.firstName,
        isStarred: talentPoolUserDto.isStarred,
        lastName: talentPoolUserDto.lastName,
        profilePicture: talentPoolUserDto.profilePicture,
        source: talentPoolUserDto.source,
        userKey: talentPoolUserDto.userKey,
      })),
    },
    offers: {
      closed: liveInsightsDto.offers.closed,
      draft: liveInsightsDto.offers.draft,
      live: liveInsightsDto.offers.live,
      scheduled: liveInsightsDto.offers.scheduled,
    },
  };
}

export function insightsItemMapper(
  insightsItemDto: InsightsItemDto,
): InsightsItem {
  switch (insightsItemDto.type) {
    case 'job':
      return {
        jobKey: insightsItemDto.jobKey,
        label: insightsItemDto.label,
        type: 'job',
      };

    case 'company_profile':
      return {
        companyProfileKey: insightsItemDto.companyProfileKey,
        label: insightsItemDto.label,
        type: 'company_profile',
      };

    case 'company_ad':
      return {
        companyAdKey: insightsItemDto.companyAdKey,
        label: insightsItemDto.label,
        type: 'company_ad',
      };

    case 'offer':
      return {
        offerKey: insightsItemDto.offerKey,
        label: insightsItemDto.label,
        type: 'offer',
      };
  }
}

export function timeBasedInsightsMapper(
  timeBasedInsightsDto: TimeBasedInsightsDto,
): TimeBasedInsights {
  return {
    tableEntries: timeBasedInsightsDto.tableEntries.map((tableEntry) => {
      if (tableEntry.type === 'total') {
        return {
          ...tableEntry,
          label: translate('label.select-products-menu.total'),
        };
      }

      return tableEntry;
    }),

    timeSeries: timeBasedInsightsDto.timeSeries,
  };
}

export function getTimeSeriesInsightsMapper(
  params: GetTimeBasedInsights,
): GetTimeSeriesInsightsDto {
  return {
    comparedPeriod: params.comparedPeriod,
    items: params.items,
    mainPeriod: params.mainPeriod,
    metrics: params.metrics,
    showUnique: params.showUnique,
    include: params.include,
  };
}
