import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  ResponsiveBox,
  Text,
  Tooltip,
  Trans,
  downloadFileContent,
  useMenu,
} from '@orbiapp/components';

import { CONTACT_CUSTOMER_SUCCESS_URL } from '../../../constants';
import {
  GetTimeBasedInsights,
  InsightsMetric,
  InsightsRange,
} from '../../../models';
import {
  ExportInsightsSelector,
  ModuleSettingsSelector,
  exportInsightsThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { getInsightsPoints, getInsightsPointsParams } from '../../../utils';

interface ExportDataButtonProps {
  metrics: InsightsMetric[];
  showUnique: boolean;
  insightsDataRange: InsightsRange;
  items: GetTimeBasedInsights['items'];
}

export function ExportDataButton(props: ExportDataButtonProps) {
  const { metrics, showUnique, insightsDataRange, items } = props;

  const exportDataStatus = useSelector(ExportInsightsSelector.selectStatus);

  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const dispatch = useDispatch();

  const menuState = useMenu();

  if (insightsTier !== 't2') {
    return (
      <ResponsiveBox
        ml="auto"
        my="auto"
        md={
          <Tooltip
            placement="left"
            clickable
            contentElement={
              <Text variant="bodySm" color="tooltipLabel">
                <Trans
                  i18nKey="label.upgrade-account-to-unlock"
                  components={{
                    link: (
                      <Link
                        style={{ display: 'inline-block' }}
                        small
                        variant="quaternary"
                        tx="label.connect.contact-sales"
                        href={CONTACT_CUSTOMER_SUCCESS_URL}
                      />
                    ),
                  }}
                />
              </Text>
            }
          >
            <Button
              height="fit-content"
              tx="button.export"
              variant="primary"
              icon="lock-closed-solid"
            />
          </Tooltip>
        }
      />
    );
  }

  const exportMetricsBreakdown = async () => {
    const res = await dispatch(
      exportInsightsThunk({
        showUnique,
        metrics,
        items,
        include: ['table_breakdown'],
        ...getInsightsPoints(getInsightsPointsParams(insightsDataRange)),
      }),
    );

    if (res.payload instanceof Blob) {
      downloadFileContent(res.payload, 'metrics-breakdown.csv');
    }
  };

  const exportGraphData = async () => {
    const res = await dispatch(
      exportInsightsThunk({
        showUnique,
        metrics,
        items,
        include: ['time_series'],
        ...getInsightsPoints(getInsightsPointsParams(insightsDataRange)),
      }),
    );

    if (res.payload instanceof Blob) {
      downloadFileContent(res.payload, 'graph-data.csv');
    }
  };

  return (
    <ResponsiveBox
      ml="auto"
      my="auto"
      md={
        <Box ref={menuState.menuRef} relative>
          <Button
            height="fit-content"
            isLoading={exportDataStatus === 'pending'}
            tx="button.export"
            onClick={menuState.toggleMenu}
            variant="primary"
            icon={menuState.isOpen ? 'chevron-up' : 'chevron-down'}
            iconPlacement="right"
          />

          <Menu absolute isOpen={menuState.isOpen} mt={10} right={0}>
            <MenuItem onClick={exportMetricsBreakdown}>
              <Text
                as="span"
                tx="label.export-insights.metrics-breakdown"
                variant="bodyMd"
                whiteSpace="nowrap"
              />
            </MenuItem>
            <MenuItem onClick={exportGraphData}>
              <Text
                as="span"
                tx="label.export-insights.graph-data"
                variant="bodyMd"
                whiteSpace="nowrap"
              />
            </MenuItem>
          </Menu>
        </Box>
      }
    />
  );
}
