import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  CompanyQr,
  CompanyQrOrderBy,
  CreateCompanyQr,
  PartialCompanyQr,
  UpdateCompanyQr,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { CompanyQrDto, PartialCompanyQrDto } from './company-qr.dto';
import {
  companyQrMapper,
  createCompanyQrMapper,
  partialCompanyQrMapper,
  updateCompanyQrMapper,
} from './company-qr.mappers';

export const createCompanyQr = async (
  createCompanyQrParams: CreateCompanyQr,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ companyQrKey: string }, any>(
    '/v2/connect/company-qr',
    createCompanyQrMapper(createCompanyQrParams),
  );

  if (!res.ok) {
    Logger.warning('createCompanyQr', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.companyQrKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createCompanyQr', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyQr = async (
  companyQrKey: string,
): Promise<GeneralApiResultWithData<CompanyQr>> => {
  const res = await apisauce.get<CompanyQrDto, any>(
    `/v2/connect/company-qr/${companyQrKey}`,
  );

  if (!res.ok) {
    Logger.warning('getCompanyQr', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = companyQrMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyQr', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPartialCompanyQrs = async (params: {
  pagination: Pagination<CompanyQrOrderBy>;
}): Promise<GeneralApiResultWithData<PartialCompanyQr[]>> => {
  const res = await apisauce.get<PartialCompanyQrDto[], any>(
    `/v2/connect/company-qr`,
    params.pagination,
  );

  if (!res.ok) {
    Logger.warning('getCompanyQrs', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialCompanyQrMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyQrs', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCompanyQr = async (
  updateCompanyQrParams: UpdateCompanyQr & { companyQrKey: string },
): Promise<GeneralApiResult> => {
  const { companyQrKey, ...rest } = updateCompanyQrParams;

  const res = await apisauce.patch<undefined, any>(
    `/v2/connect/company-qr/${companyQrKey}`,
    updateCompanyQrMapper(rest),
  );

  if (!res.ok) {
    Logger.warning('updateCompanyQr', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
