import {
  Base64File,
  Box,
  COVER_IMAGE_CRITERIA,
  CropState,
  FileType,
  ImageCropper,
  Text,
  Upload,
  UploadError,
  UploadResult,
  constructBase64,
  getObjectValue,
  isTxString,
  removeBase64Meta,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { GlobalUiStateAlert } from '../../models';
import { getAlert, imageMeetsCriteria } from '../../utils';
import { Styled } from './upload-cover-image.styled';

interface UploadCoverImageProps {
  name: string;
  disabled?: boolean;

  onChange?: (coverImage: Base64File | null) => void;
}

export function UploadCoverImage(props: UploadCoverImageProps) {
  const { name, disabled, onChange } = props;

  const { formState, watch, setValue } = useFormContext();

  const { closeModal, isOpen, openModal } = useModalState();

  const [uploadError, setUploadError] =
    React.useState<GlobalUiStateAlert | null>(null);

  const coverImage: Base64File | null = watch(name);

  const uploadResultRef = React.useRef<UploadResult | null>(
    coverImage
      ? {
          height: 0,
          width: 0,
          base64: coverImage.base64,
          size: 0,
          type: coverImage.fileType,
          name: coverImage.fileName,
        }
      : null,
  );

  const [cropState, setCropState] = React.useState<CropState>({
    aspect: 16 / 10,
    base64File: coverImage,
    x: 0,
    y: 0,
    zoom: 1,
  });

  const handleUpload = (uploadResult: UploadResult) => {
    setUploadError(null);

    uploadResultRef.current = uploadResult;

    openModal();

    setCropState({
      aspect: 16 / 10,
      base64File: {
        base64: removeBase64Meta(uploadResult.base64),
        fileName: uploadResult.name,
        fileType: uploadResult.type as FileType,
      },
      x: 0,
      y: 0,
      zoom: 1,
    });
  };

  const saveCoverImage = (newCropState: CropState | null) => {
    if (!newCropState) return;

    setCropState(newCropState);

    setValue(name, newCropState.base64File, {
      shouldValidate: formState.isSubmitted,
      shouldDirty: true,
    });
    onChange?.(newCropState.base64File);

    closeModal();
  };

  const handleUploadError = (err: UploadError) => {
    if (err.message === 'accept') {
      setUploadError({
        alertType: 'file-type-not-allowed',
      });
      return;
    }

    const error = imageMeetsCriteria(COVER_IMAGE_CRITERIA, {
      height: err.height,
      width: err.width,
      size: err.size,
    });

    setUploadError(error);
  };

  const errorTx =
    getObjectValue<any>(formState.errors, name)?.message ??
    getObjectValue<any>(formState.errors, `${name}.base64`)?.message ??
    getObjectValue<any>(formState.errors, `${name}.fileName`)?.message ??
    getObjectValue<any>(formState.errors, `${name}.fileType`)?.message;

  return (
    <Box
      flex
      flexAlign="center"
      flexGrow={1}
      flexWrap="wrap"
      gap={32}
      maxWidth="100%"
      minWidth={250}
    >
      <ImageCropper
        onClose={closeModal}
        isOpen={isOpen}
        onSave={saveCoverImage}
        cropState={cropState}
        closeTx="button.close"
        saveTx="button.save"
      />

      <Styled.UploadCoverImageContainer
        maxWidth="100%"
        flex
        flexDirection="column"
        gap={8}
      >
        <Upload
          accept={Object.values(FileType).join(',')}
          buttonTx={coverImage ? 'button.upload-new' : 'button.upload-image'}
          disabled={disabled}
          onError={handleUploadError}
          onUpload={handleUpload}
          src={constructBase64(coverImage)}
          titleTx="label.upload.min-size"
          {...COVER_IMAGE_CRITERIA}
        />

        <Text
          color="errorLabel"
          tx={
            uploadError
              ? getAlert(uploadError.alertType).subtitleTx
              : isTxString(errorTx)
              ? errorTx
              : undefined
          }
          txArgs={uploadError?.args}
          variant="bodyMd"
        />
      </Styled.UploadCoverImageContainer>
    </Box>
  );
}
