import {
  Address,
  Coordinates,
  GeneralApiResultWithData,
  SearchAddressesParams,
  SearchCoordinatesParams,
} from '@orbiapp/components';

import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';

export const searchCoordinates = async (
  params: SearchCoordinatesParams,
): Promise<GeneralApiResultWithData<Coordinates>> => {
  const res = await apisauce.post<Coordinates, any>(
    '/v1/search/coordinates',
    params,
  );

  if (!res.ok) {
    Logger.warning('searchCoordinates', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchCoordinates', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const searchAddresses = async (
  params: SearchAddressesParams,
): Promise<GeneralApiResultWithData<Address[]>> => {
  const res = await apisauce.post<Address[], any>(
    '/v1/search/addresses',
    params,
  );

  if (!res.ok) {
    Logger.warning('searchAddresses', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchAddresses', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
