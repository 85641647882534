import {
  Text,
  TextProps,
  Time,
  parseTimestamp,
  translate,
} from '@orbiapp/components';
import React from 'react';

export function DraftUpdatedAgoText(
  props: { updatedAt?: number | null } & TextProps,
) {
  const { updatedAt, ...rest } = props;

  const savedAgoTextRef = React.useRef<HTMLParagraphElement>(null);

  React.useEffect(() => {
    if (!savedAgoTextRef.current || !updatedAt) return;

    const savedAgoText = savedAgoTextRef.current;

    const interval = window.setInterval(() => {
      savedAgoText.innerText = translate('label.general.draft-saved-ago', {
        timeAgo: parseTimestamp(updatedAt, 'timeAgo'),
      });
    }, 30 * Time.Second);

    return () => {
      window.clearInterval(interval);
    };
  }, [updatedAt]);

  if (!updatedAt) {
    return null;
  }

  return (
    <Text
      ref={savedAgoTextRef}
      variant="bodySm"
      text={translate('label.general.draft-saved-ago', {
        timeAgo: parseTimestamp(updatedAt, 'timeAgo'),
      })}
      color="formHeaderSavedAtColor"
      whiteSpace="nowrap"
      {...rest}
    />
  );
}
