import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import { CompanyAdsOrderByKey, PartialCompanyAd } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { PartialCompanyAdDto } from './company-ads.dto';
import { partialCompanyAdMapper } from './company-ads.mappers';

export async function getCompanyAds(params: {
  pagination: Pagination<CompanyAdsOrderByKey>;
}): Promise<GeneralApiResultWithData<PartialCompanyAd[]>> {
  const res = await apisauce.get<PartialCompanyAdDto[], any>(
    '/v1/company-ads',
    params.pagination,
  );

  if (!res.ok) {
    Logger.warning('getCompanyAds', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialCompanyAdMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyAds', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}
