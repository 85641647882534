import {
  Box,
  EmptyState,
  InnerContentContainer,
  LinearProgress,
  Paginator,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TinySelect,
  Tooltip,
  numberFormatter,
  paginatorOptions,
} from '@orbiapp/components';
import React from 'react';

import { useDataGridPagination } from '../../../../helpers';
import {
  CompanyQrOrderBy,
  PartialCompanyQr,
  companyQrSortableKeys,
} from '../../../../models';
import {
  CompanyQrSelector,
  PartialCompanyQrsSelector,
  connectActions,
  getCompanyQrThunk,
  getPartialCompanyQrsThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import {
  getConnectConversionStatus,
  getConnectQrCodeStatus,
} from '../../../../utils';
import {
  AvailableQrCodesStatusChip,
  ConnectSidebarContext,
  InfoHeader,
  PageHeader,
  StatsCards,
} from '../components';

const TABLE_COLUMN_WIDTHS = {
  name: 200,
  status: 220,
  scanCount: 40,
  connectCount: 40,
  conversion: 200,
  shadow: 50,
};

function PartialQrCodeTableRow(props: PartialCompanyQr) {
  const { name, startDate, endDate, scanCount, connectCount, companyQrKey } =
    props;

  const companyQr = useSelector(CompanyQrSelector.selectCompanyQr);

  const dispatch = useDispatch();

  const { closeSidebar } = React.useContext(ConnectSidebarContext);

  const highlight = companyQr?.companyQrKey === companyQrKey;

  const selectQrCode = async () => {
    if (highlight) return;

    await dispatch(getCompanyQrThunk(companyQrKey));

    closeSidebar();
  };

  let conversion = Math.round((connectCount / scanCount) * 100);

  if (isNaN(conversion) || conversion === Infinity) {
    conversion = 0;
  }
  if (conversion > 100) {
    conversion = 100;
  }

  return (
    <TableRow key={companyQrKey} onClick={selectQrCode} highlight={highlight}>
      <TableCell text={name} width={TABLE_COLUMN_WIDTHS.name} />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status {...getConnectQrCodeStatus(startDate, endDate)} />
      </TableCell>

      <TableCell
        text={numberFormatter.format(scanCount)}
        width={TABLE_COLUMN_WIDTHS.scanCount}
      />

      <TableCell
        text={numberFormatter.format(connectCount)}
        width={TABLE_COLUMN_WIDTHS.connectCount}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.conversion}>
        <Tooltip placement="bottom" title={`${conversion}%`}>
          <LinearProgress
            percentage={conversion}
            width={152}
            variant={getConnectConversionStatus(conversion)}
          />
        </Tooltip>
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.shadow} fixedRight />
    </TableRow>
  );
}

function renderPartialCompanyQrCode(partialCompanyQr: PartialCompanyQr) {
  return (
    <PartialQrCodeTableRow
      key={partialCompanyQr.companyQrKey}
      {...partialCompanyQr}
    />
  );
}

function QrCodesTable() {
  const companyQrs = useSelector(PartialCompanyQrsSelector.selectAll);
  const pagination = useSelector(PartialCompanyQrsSelector.selectPagination);
  const status = useSelector(PartialCompanyQrsSelector.selectStatus);

  const { openSidebar } = React.useContext(ConnectSidebarContext);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialCompanyQr, CompanyQrOrderBy>({
      data: companyQrs,
      pagination,
      reset: connectActions.clearPartialCompanyQrs,
      thunk: getPartialCompanyQrsThunk,
    });

  const isLoading = status === 'pending';
  const isEmpty = rows.length === 0;

  if (isEmpty) {
    if (isLoading) {
      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.connect.name" />
              <TableHead tx="label.connect.status" />
              <TableHead tx="label.connect.scans" />
              <TableHead tx="label.connect.talent-acquired" />
              <TableHead tx="label.connect.conversion" />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
          <TableBody data-testid="qr-codes-loading-state-table-body">
            <TablePlaceholderRows
              rowCount={10}
              layout={Object.values(TABLE_COLUMN_WIDTHS)}
            />
          </TableBody>
        </Table>
      );
    }

    return (
      <EmptyState
        titleTx="label.connect.no-qr-codes-available"
        buttonTx="label.connect.new-qr-code"
        buttonOnClick={openSidebar}
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          sortableColumns={Object.values(companyQrSortableKeys)}
          onSort={onSort}
          orderBy={pagination.orderBy}
          sortOrder={pagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.connect.name"
              name={companyQrSortableKeys.name}
            />
            <TableHead tx="label.connect.status" />
            <TableHead
              tx="label.connect.scans"
              name={companyQrSortableKeys.scanCount}
            />
            <TableHead
              tx="label.connect.talent-acquired"
              name={companyQrSortableKeys.connectCount}
            />
            <TableHead tx="label.connect.conversion" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody data-testid="qr-codes-table-body">
          {rows.map(renderPartialCompanyQrCode)}
        </TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>
    </React.Fragment>
  );
}

export function QrCodes() {
  return (
    <InnerContentContainer>
      <PageHeader />

      <InfoHeader>
        <StatsCards />

        <AvailableQrCodesStatusChip />
      </InfoHeader>

      <QrCodesTable />
    </InnerContentContainer>
  );
}
