import { createSelector } from '@reduxjs/toolkit';

import { AuthStateSelector } from '../auth';
import { RootState } from '../store.types';

export namespace FeatureFlagsSelector {
  const selectSelf = (state: RootState) => state.featureFlags.featureFlags;

  export const selectStatus = createSelector(
    selectSelf,
    (featureFlags) => featureFlags.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (featureFlags) => featureFlags.data,
  );

  export const selectEnableOffers = createSelector(
    [selectData, AuthStateSelector.selectIsSuperAdmin],
    (data, isSuperAdmin) => data?.COMPANY_DASHBOARD_OFFERS || isSuperAdmin,
  );

  export const selectEnableOffersInternalPreview = createSelector(
    [selectData, AuthStateSelector.selectIsSuperAdmin],
    (data, isSuperAdmin) => !data?.COMPANY_DASHBOARD_OFFERS && !!isSuperAdmin,
  );
}
