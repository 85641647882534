import {
  Avatar,
  Box,
  Button,
  Card,
  Confirm,
  FormHeader,
  Icon,
  IconButton,
  SolidIconButton,
  Status,
  StatusVariant,
  Text,
  Time,
  Tooltip,
  TxArgs,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  numberFormatter,
  parseTimestamp,
  translate,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { PublishButton } from '../../../../../components';
import { Job, UpdateJobForm } from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CloseJobSelector,
  JobSelector,
  UpdateJobSelector,
  closeJobThunk,
  globalUiStateActions,
  setAlert,
  updateJobThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';

function JobStats() {
  const jobStats = useSelector(JobSelector.selectStats);
  const totalBudget = useSelector(JobSelector.selectTotalBudget);
  const dailyBudget = useSelector(JobSelector.selectDailyBudget);
  if (!jobStats) {
    return null;
  }

  return (
    <Box gap={16} flexWrap flex pt={4}>
      <Card minWidth={180} flexGrow={1} flexBasis={0} py={8} minHeight={68}>
        <Box>
          <Text tx="label.job-stats.total-budget" variant="bodyMd" />

          {totalBudget !== null ? (
            <Box gap={4} flexAlign="end" flex>
              <Text
                text={numberFormatter.format(totalBudget)}
                variant="titleSm"
              />
              <Text text="SEK" variant="bodySm" />
            </Box>
          ) : (
            <Text variant="bodyMdItalic" tx="label.job-form.not-specified" />
          )}
        </Box>
      </Card>

      <Card minWidth={180} flexGrow={1} flexBasis={0} py={8} minHeight={68}>
        <Box>
          <Text tx="label.job-stats.daily-budget" variant="bodyMd" />

          {dailyBudget !== null ? (
            <Box gap={4} flexAlign="end" flex>
              <Text
                text={numberFormatter.format(dailyBudget)}
                variant="titleSm"
              />
              <Text text="SEK" variant="bodySm" />
            </Box>
          ) : (
            <Text tx="label.job-form.not-specified" />
          )}
        </Box>
      </Card>

      <Card minWidth={180} flexGrow={1} flexBasis={0} py={8} minHeight={68}>
        <Box>
          <Text tx="label.job-stats.budget-spent" variant="bodyMd" />
          <Box gap={4} flexAlign="end" flex>
            <Text
              text={numberFormatter.format(jobStats.totalBudgetSpend)}
              variant="titleSm"
            />
            <Text text="SEK" variant="bodySm" />
          </Box>
        </Box>
      </Card>

      <Card minWidth={180} flexGrow={1} flexBasis={0} py={8} minHeight={68}>
        <Box>
          <Text tx="label.job-stats.clicks" variant="bodyMd" />
          <Box gap={4} flexAlign="end" flex>
            <Text
              text={numberFormatter.format(jobStats.clickCount)}
              variant="titleSm"
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

function getJobStatusOptions(partialJob: {
  dailyBudgetReachedAt: Job['dailyBudgetReachedAt'];
  endDate: Job['endDate'];
  startDate: Job['startDate'];
  isManuallyClosed: Job['isManuallyClosed'];
  totalBudgetReachedAt: Job['totalBudgetReachedAt'];
}): {
  variant: StatusVariant;
  tx: TxString;
  txArgs?: TxArgs;
} {
  const now = Date.now();
  const isExpired = now > partialJob.endDate;

  if (isExpired || partialJob.isManuallyClosed) {
    return {
      variant: 'error',
      tx: 'label.job-status.closed',
    };
  }

  if (partialJob.totalBudgetReachedAt) {
    return {
      variant: 'error',
      tx: 'label.job-status.total-budget-reached',
    };
  }

  if (
    partialJob.dailyBudgetReachedAt &&
    partialJob.dailyBudgetReachedAt.some(
      (timestamp) => timestamp > now - Time.Day,
    )
  ) {
    return {
      variant: 'info',
      tx: 'label.job-status.daily-budget-reached',
    };
  }

  if (partialJob.startDate > now) {
    return {
      variant: 'info',
      tx: 'label.job-status.scheduled-at',
      txArgs: {
        date: parseTimestamp(partialJob.startDate, 'DD MMM YYYY HH:mm'),
      },
    };
  }

  return {
    variant: 'success',
    tx: 'label.job-status.live',
  };
}

function JobFormHeaderActions() {
  const endDate = useSelector(JobSelector.selectEndDate);
  const isManuallyClosed = useSelector(JobSelector.selectIsManuallyClosed);
  const startDate = useSelector(JobSelector.selectStartDate);
  const jobKey = useSelector(JobSelector.selectJobKey);
  const closeJobStatus = useSelector(CloseJobSelector.selectStatus);
  const updateJobStatus = useSelector(UpdateJobSelector.selectStatus);
  const jobData = useSelector(JobSelector.selectData);

  const { openConfirm, isOpen, closeConfirm } = useConfirm();

  const dispatch = useDispatch();

  const formContext = useFormContext<UpdateJobForm>();

  if (!endDate || !startDate || !jobKey) {
    return null;
  }

  const closeJob = async () => {
    const res = await dispatch(closeJobThunk(jobKey));

    if (res.meta.requestStatus === 'fulfilled') {
      closeConfirm();
    }
  };

  const saveJobForm = formContext.handleSubmit(
    async (data) => {
      const res = await dispatch(updateJobThunk(data));

      if (res.meta.requestStatus === 'fulfilled') {
        formContext.reset(data);
      }
    },
    (err) => {
      Logger.warning('updateJob Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const publishJob = async (updateJobForm: UpdateJobForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(updateJobThunk(updateJobForm));
    if (res.payload && 'jobKey' in res.payload) {
      formContext.reset(updateJobForm);
    }
  };

  const handleError = (err: FieldErrors<UpdateJobForm>) => {
    Logger.warning('updateJob Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  return (
    <React.Fragment>
      <Confirm
        onConfirm={closeJob}
        onCancel={closeConfirm}
        isOpen={isOpen}
        isLoading={closeJobStatus === 'pending'}
        titleTx="prompt.close-job.title"
        messageTx="prompt.close-job.message"
        cancelTx="prompt.close-job.cancel"
        confirmTx="prompt.close-job.confirm"
      />

      <Box flexAlign="start" flexWrap flex gap={16}>
        {endDate > Date.now() && !isManuallyClosed && (
          <Button
            isLoading={updateJobStatus === 'pending'}
            variant="primary"
            tx="button.save"
            onClick={saveJobForm}
          />
        )}

        {endDate > Date.now() && !isManuallyClosed && (
          <Button
            icon="power"
            onClick={openConfirm}
            tx="button.close"
            variant="destructive"
          />
        )}

        <Tooltip placement="bottom" titleTx="button.duplicate">
          <SolidIconButton
            icon="document-duplicate-outline"
            to={`/jobs/duplicate-job/${jobKey}`}
            state={{ job: jobData }}
          />
        </Tooltip>

        {Date.now() < startDate && !isManuallyClosed && (
          <PublishButton
            onError={handleError}
            onSubmit={publishJob}
            isLoading={updateJobStatus === 'pending'}
          />
        )}
      </Box>
    </React.Fragment>
  );
}

export function JobFormHeader() {
  const totalBudgetReachedAt = useSelector(JobSelector.totalBudgetReachedAt);
  const dailyBudgetReachedAt = useSelector(
    JobSelector.selectDailyBudgetReachedAt,
  );
  const endDate = useSelector(JobSelector.selectEndDate);
  const startDate = useSelector(JobSelector.selectStartDate);
  const isManuallyClosed = useSelector(JobSelector.selectIsManuallyClosed);
  const editMeta = useSelector(JobSelector.selectEditMeta);

  const fullName = editMeta?.createdBy
    ? `${editMeta.createdBy.firstName} ${editMeta.createdBy?.lastName}`
    : translate('placeholder.unknown-user');

  return (
    <FormHeader flex flexDirection="column" gap={12} width="100%">
      <Box
        flex
        flexAlign="center"
        flexJustify="between"
        gap={24}
        width="100%"
        flexWrap
      >
        <Box flexGrow={1} gap={8} flex flexDirection="column">
          <Box gap={8} flexAlign="center" flex>
            <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
              <IconButton to="/jobs" icon="chevron-left" />
            </Tooltip>

            <Text
              color="pageTitle"
              variant="titleMd"
              tx="title.jobs.update-job"
              as="h1"
            />
          </Box>
        </Box>

        <JobFormHeaderActions />
      </Box>

      <Box gap={16} flexWrap flex flexJustify="between">
        <Box flexWrap flexAlign="center" gap={16} flex>
          <Box flex flexAlign="center" mr={48} gap={16}>
            <Icon
              color="formHeaderEditedByMetaIcon"
              name="user-circle-outline"
            />
            <Text
              color="formHeaderEditedByMetaTitle"
              tx="label.job-form.created-by"
              variant="bodySm"
            />

            <Box flexAlign="center" gap={8} flex>
              <Avatar
                variant={
                  editMeta?.updatedBy?.userKey
                    ? getAvatarVariantFromString(editMeta.updatedBy.userKey)
                    : undefined
                }
                width={32}
                height={32}
                fallbackLetter={editMeta?.createdBy?.firstName[0]}
                src={editMeta?.createdBy?.profilePicture?.thumbnail64.url}
              />

              <Text
                color="formHeaderEditedByMeta"
                text={fullName}
                textTransform="capitalize"
                variant="bodySm"
              />
            </Box>
          </Box>

          {editMeta?.updatedAt && (
            <Box flex flexAlign="center" gap={16}>
              <Icon name="clock-outline" color="formHeaderEditedByMetaIcon" />
              <Text
                variant="bodySm"
                color="formHeaderEditedByMetaTitle"
                tx="label.view-job.last-updated"
              />
              <Text
                variant="bodySm"
                text={parseTimestamp(editMeta?.updatedAt, 'ddd DD MMM')}
              />
            </Box>
          )}
        </Box>

        {endDate && startDate && (
          <Status
            {...getJobStatusOptions({
              dailyBudgetReachedAt,
              endDate,
              isManuallyClosed,
              startDate,
              totalBudgetReachedAt,
            })}
          />
        )}
      </Box>

      <JobStats />
    </FormHeader>
  );
}
