const env_vars = {
  API_URL: import.meta.env.VITE_APP_API_URL,
  AUTH_URL: import.meta.env.VITE_APP_AUTH_URL,
  WS_API_URL: import.meta.env.VITE_APP_WS_API_URL,

  STUDENT_DASHBOARD_URL: import.meta.env.VITE_APP_STUDENT_DASHBOARD_URL,

  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,

  DYNAMIC_LINK_PATH: 'link.orbiapp.io',
  ENV: 'production',
  ANDROID_PACKAGE_NAME: 'com.campusmobile',
  IOS_BUNDLE_ID: 'com.dipitytechnologies.campusappen',
};

if (env_vars.FIREBASE_PROJECT_ID === 'orbi-testing') {
  env_vars.DYNAMIC_LINK_PATH = 'testing.link.orbiapp.io';
  env_vars.ENV = 'testing';
  env_vars.ANDROID_PACKAGE_NAME = 'com.campusmobile';
  env_vars.IOS_BUNDLE_ID = 'io.orbiapp.orbi.testing';
}

if (!import.meta.env.PROD) {
  Object.keys(env_vars).forEach((key) => {
    if (env_vars[key as keyof typeof env_vars] === undefined) {
      throw new Error(
        `Error. Value of ${key} must be set in order for application to run properly.`,
      );
    }
  });
}

export const firebaseConfig = {
  dynamicLinkPath: env_vars.DYNAMIC_LINK_PATH,
  apiKey: env_vars.FIREBASE_API_KEY,
  authDomain: env_vars.FIREBASE_AUTH_DOMAIN,
  projectId: env_vars.FIREBASE_PROJECT_ID,
  appId: env_vars.FIREBASE_APP_ID,
  measurementId: env_vars.FIREBASE_MEASUREMENT_ID,
};

export const ENV = env_vars.ENV as 'testing' | 'production';

export const API_URL = env_vars.API_URL;
export const AUTH_URL = env_vars.AUTH_URL;
export const WS_API_URL = env_vars.WS_API_URL;

export const STUDENT_DASHBOARD_URL = env_vars.STUDENT_DASHBOARD_URL;

export const HOTJAR_ID = 3043557;
export const HOTJAR_VERSION_NUMBER = 6;

export const ANDROID_PACKAGE_NAME = env_vars.ANDROID_PACKAGE_NAME;
export const IOS_APP_STORE_ID = '1226346650';
export const IOS_BUNDLE_ID = env_vars.IOS_BUNDLE_ID;
