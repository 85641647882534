import {
  Avatar,
  Box,
  Button,
  Confirm,
  DangerIconButton,
  FormHeader,
  Icon,
  IconButton,
  SolidIconButton,
  Status,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  isTxString,
  parseTimestamp,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { DraftUpdatedAgoText } from '../../../../../components';
import { CompanyProfileForm } from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CompanyProfileDraftsSelector,
  CompanyProfilesSelector,
  deleteCompanyProfileDraftThunk,
  deleteCompanyProfileThunk,
  updateCompanyProfileThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { ProfileContext } from './profile-form.context';

function ConfirmDeleteProfileModal() {
  const { closeConfirmDeleteProfile, deleteProfileIsOpen, profile } =
    React.useContext(ProfileContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCompanyProfileIsLoading = useSelector(
    CompanyProfilesSelector.deleteCompanyProfileIsLoading,
  );

  const deleteCompanyProfileDraftIsLoading = useSelector(
    CompanyProfileDraftsSelector.deleteCompanyProfileDraftIsLoading,
  );

  if (!profile) {
    return null;
  }

  const isLoading =
    deleteCompanyProfileIsLoading || deleteCompanyProfileDraftIsLoading;

  const isDraft = 'companyProfileDraftKey' in profile;

  const deleteProfile = async () => {
    const res = isDraft
      ? await dispatch(
          deleteCompanyProfileDraftThunk(profile.companyProfileDraftKey),
        )
      : await dispatch(deleteCompanyProfileThunk(profile.companyProfileKey));

    if (res.meta.requestStatus === 'fulfilled') {
      navigate('/profiles', { replace: true });
    }
  };

  return (
    <Confirm
      cancelTx={
        isDraft
          ? 'prompt.delete-company-profile-draft.cancel'
          : 'prompt.delete-company-profile.cancel'
      }
      confirmTx={
        isDraft
          ? 'prompt.delete-company-profile-draft.confirm'
          : 'prompt.delete-company-profile.confirm'
      }
      isLoading={isLoading}
      isOpen={deleteProfileIsOpen}
      messageTx={
        isDraft
          ? 'prompt.delete-company-profile-draft.message'
          : 'prompt.delete-company-profile.message'
      }
      messageTxArgs={{
        name: profile?.name ?? translate('label.general.untitled-draft'),
      }}
      onCancel={closeConfirmDeleteProfile}
      onConfirm={deleteProfile}
      titleTx={
        isDraft
          ? 'prompt.delete-company-profile-draft.title'
          : 'prompt.delete-company-profile.title'
      }
    />
  );
}

export function ProfileHeader() {
  const createCompanyProfileIsLoading = useSelector(
    CompanyProfilesSelector.createCompanyProfileIsLoading,
  );
  const updateCompanyProfileIsLoading = useSelector(
    CompanyProfilesSelector.updateCompanyProfileIsLoading,
  );

  const dispatch = useDispatch();

  const { openConfirmDeleteProfile, publishProfile, profile, formMode } =
    React.useContext(ProfileContext);

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CompanyProfileForm>();

  const isDraft = formMode === 'createProfile';

  const fullName = profile?.editMeta.updatedBy
    ? `${profile.editMeta.updatedBy.firstName} ${profile.editMeta.updatedBy?.lastName}`
    : translate('placeholder.unknown-user');

  const duplicateButtonTo = `/profiles/create-profile/${
    !profile
      ? ''
      : 'companyProfileKey' in profile
      ? `duplicate/${profile.companyProfileKey}`
      : `duplicate-draft/${profile?.companyProfileDraftKey}`
  }`;

  const saveProfileForm = handleSubmit(
    async (data) => {
      if (!profile || !('companyProfileKey' in profile)) return;

      const res = await dispatch(
        updateCompanyProfileThunk({
          companyProfileKey: profile.companyProfileKey,
          updateCompanyProfile: {
            about: data.about ?? undefined,
            contactEmail: data.contactEmail ?? undefined,
            contactPhone: data.contactPhone ?? undefined,
            coverImageBase64: data.coverImageBase64 ?? undefined,
            employmentTypes: data.employmentTypes ?? undefined,
            funFacts: data.funFacts ?? undefined,
            locationData: data.locationData ?? undefined,
            name: data.name ?? undefined,
            oneliner: data.oneliner ?? undefined,
            perks: data.perks ?? undefined,
            subjectAreaKeys: data.subjectAreaKeys ?? undefined,
            videoUrl: data.videoUrl ?? undefined,
            websiteUrl: data.websiteUrl ?? undefined,
          },
        }),
      );

      if (res.meta.requestStatus === 'fulfilled') {
        reset(data);
      }
    },
    (err) => {
      Logger.warning('updateCompanyProfile Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <React.Fragment>
      <ConfirmDeleteProfileModal />

      <FormHeader flex flexDirection="column" width="100%" gap={12}>
        <Box flex flexAlign="start" flexJustify="between" gap={24}>
          <Box gap={8} pt={3} flex width="100%">
            <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
              <IconButton to="/profiles" icon="chevron-left" />
            </Tooltip>

            <Box flex flexDirection="column" width="100%">
              <Text
                color="pageTitle"
                variant="titleMd"
                tx={
                  formMode === 'createProfile'
                    ? 'title.profiles.create-profile'
                    : 'title.profiles.edit-profile'
                }
                as="h1"
              />

              <Text
                variant="bodyMd"
                tx={
                  isTxString(errors.name?.message)
                    ? errors.name?.message
                    : undefined
                }
                color="errorLabel"
              />
            </Box>
          </Box>

          <Box gap={24} flex flexAlign="center">
            {isDraft && (
              <DraftUpdatedAgoText updatedAt={profile?.editMeta.updatedAt} />
            )}

            {!isDraft && (
              <Button
                tx="button.save"
                variant="primary"
                onClick={saveProfileForm}
                isLoading={updateCompanyProfileIsLoading}
              />
            )}

            <Tooltip placement="bottom" titleTx="label.tooltip.delete">
              <DangerIconButton
                icon="trash-outline"
                onClick={openConfirmDeleteProfile}
              />
            </Tooltip>

            <Tooltip placement="bottom" titleTx="label.tooltip.duplicate">
              <SolidIconButton
                icon="document-duplicate-outline"
                to={duplicateButtonTo}
              />
            </Tooltip>

            {isDraft && (
              <Button
                isLoading={createCompanyProfileIsLoading}
                onClick={publishProfile}
                tx="button.publish"
                variant="primary"
              />
            )}
          </Box>
        </Box>

        <Box flex flexAlign="center" gap={8}>
          <Box gap={16} flex flexAlign="center" flexWrap>
            <Box flex flexAlign="center" gap={16} mr={64}>
              <Box flex flexAlign="center" gap={16}>
                <Icon
                  name="user-circle-outline"
                  color="formHeaderEditedByMetaIcon"
                />
                <Text
                  variant="bodySm"
                  tx="label.profile.updated-by"
                  color="formHeaderEditedByMetaTitle"
                />
              </Box>
              <Box flex flexAlign="center" gap={8}>
                <Avatar
                  variant={
                    profile?.editMeta?.updatedBy?.userKey
                      ? getAvatarVariantFromString(
                          profile.editMeta?.updatedBy.userKey,
                        )
                      : undefined
                  }
                  src={
                    profile?.editMeta.updatedBy?.profilePicture?.thumbnail1024
                      .url ?? undefined
                  }
                  fallbackLetter={profile?.editMeta.updatedBy?.firstName.charAt(
                    0,
                  )}
                  width={32}
                  maxHeight={32}
                />
                <Text
                  color="formHeaderEditedByMeta"
                  variant="bodySm"
                  text={fullName}
                />
              </Box>
            </Box>

            {!isDraft && (
              <Box flex flexAlign="center" gap={16}>
                <Icon name="clock-outline" color="formHeaderEditedByMetaIcon" />
                <Text
                  variant="bodySm"
                  tx="label.profile.last-updated"
                  color="formHeaderEditedByMetaTitle"
                />
                <Text
                  color="formHeaderEditedByMeta"
                  variant="bodySm"
                  text={
                    profile?.editMeta.updatedAt
                      ? parseTimestamp(profile.editMeta.updatedAt, 'ddd DD MMM')
                      : ''
                  }
                />
              </Box>
            )}
          </Box>

          <Status
            ml="auto"
            variant={isDraft ? 'warning' : 'success'}
            tx={
              isDraft
                ? 'label.profiles.profile-status.draft'
                : 'label.profiles.profile-status.live'
            }
          />
        </Box>
      </FormHeader>
    </React.Fragment>
  );
}
