export type CompanyAdPlacement = 'carousel' | 'feed' | 'ticket';

export interface PartialCompanyAd {
  companyAdKey: string;

  title: string;
  placement: CompanyAdPlacement;
  activeFrom: number;
  activeTo: number;
}

export type CompanyAdsOrderByKey = keyof Pick<
  PartialCompanyAd,
  'title' | 'placement' | 'activeFrom'
>;

export const companyAdsSortableKeys: Record<
  CompanyAdsOrderByKey,
  CompanyAdsOrderByKey
> = {
  title: 'title',
  placement: 'placement',
  activeFrom: 'activeFrom',
};
