import { initialAccountState } from './account';
import { initialAuthState } from './auth';
import { initialCompanyState } from './company';
import { initialCompanyAdsState } from './company-ads';
import { initialCompanyProfileDraftsState } from './company-profile-drafts';
import { initialCompanyProfilesState } from './company-profiles';
import { initialConnectState } from './connect';
import { initialCountriesState } from './countries';
import { initialFeatureFlagsState } from './feature-flags';
import { initialGlobalUiState } from './global-ui-state';
import { initialInsightsState } from './insights';
import { initialJobsState } from './jobs';
import { initialLocationsState } from './locations';
import { initialOffersState } from './offers';
import { initialPerksState } from './perks';
import { initialSearchState } from './search';
import { RootState } from './store.types';
import { initialSubjectAreasState } from './subject-areas';
import { initialTeamState } from './team';

export function getStateAfterSignOut(state?: Partial<RootState>): RootState {
  return {
    account: initialAccountState,
    auth: initialAuthState,
    company: initialCompanyState,
    companyProfileDrafts: initialCompanyProfileDraftsState,
    companyProfiles: initialCompanyProfilesState,
    connect: initialConnectState,
    featureFlags: state?.featureFlags ?? initialFeatureFlagsState,
    globalUiState: initialGlobalUiState,
    insights: initialInsightsState,
    jobs: initialJobsState,
    offers: initialOffersState,
    perks: initialPerksState,
    subjectAreas: initialSubjectAreasState,
    team: initialTeamState,
    companyAds: initialCompanyAdsState,
    locations: initialLocationsState,
    search: initialSearchState,
    countries: initialCountriesState,
  };
}

export function getStateAfterSwitchWorkspace(
  state?: Partial<RootState>,
): RootState {
  return {
    account: initialAccountState,
    auth: state?.auth ?? initialAuthState,
    company: initialCompanyState,
    companyProfileDrafts: initialCompanyProfileDraftsState,
    companyProfiles: initialCompanyProfilesState,
    connect: initialConnectState,
    featureFlags: state?.featureFlags ?? initialFeatureFlagsState,
    globalUiState: initialGlobalUiState,
    insights: initialInsightsState,
    jobs: initialJobsState,
    offers: initialOffersState,
    perks: initialPerksState,
    subjectAreas: initialSubjectAreasState,
    team: initialTeamState,
    companyAds: initialCompanyAdsState,
    locations: initialLocationsState,
    search: initialSearchState,
    countries: initialCountriesState,
  };
}

export function getStateAfterEmulateUser(
  state?: Partial<RootState>,
): RootState {
  return {
    account: initialAccountState,
    auth: state?.auth ?? initialAuthState,
    company: initialCompanyState,
    companyProfileDrafts: initialCompanyProfileDraftsState,
    companyProfiles: initialCompanyProfilesState,
    connect: initialConnectState,
    featureFlags: state?.featureFlags ?? initialFeatureFlagsState,
    globalUiState: initialGlobalUiState,
    insights: initialInsightsState,
    jobs: initialJobsState,
    offers: initialOffersState,
    perks: initialPerksState,
    subjectAreas: initialSubjectAreasState,
    team: initialTeamState,
    companyAds: initialCompanyAdsState,
    locations: initialLocationsState,
    search: initialSearchState,
    countries: initialCountriesState,
  };
}
