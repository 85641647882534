import {
  Base64File,
  Box,
  CropState,
  FileType,
  ImageCropper,
  LOGO_CRITERIA,
  Text,
  Upload,
  UploadError,
  UploadResult,
  constructBase64,
  getObjectValue,
  isTxString,
  removeBase64Meta,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { GlobalUiStateAlert } from '../../models';
import { getAlert, imageMeetsCriteria } from '../../utils';

interface UploadLogoProps {
  name: string;

  width?: string | number;
  height?: string | number;
}

export function UploadLogo(props: UploadLogoProps) {
  const { name, ...rest } = props;

  const { formState, watch, setValue } = useFormContext();

  const { closeModal, isOpen, openModal } = useModalState();

  const [uploadError, setUploadError] =
    React.useState<GlobalUiStateAlert | null>(null);

  const base64File: Base64File | null = watch(name);

  const uploadResultRef = React.useRef<UploadResult | null>(
    base64File
      ? {
          height: 0,
          width: 0,
          base64: base64File.base64,
          size: 0,
          type: base64File.fileType,
          name: base64File.fileName,
        }
      : null,
  );

  const [cropState, setCropState] = React.useState<CropState>({
    aspect: 1,
    base64File,
    x: 0,
    y: 0,
    zoom: 1,
  });

  const handleUpload = (uploadResult: UploadResult) => {
    setUploadError(null);

    uploadResultRef.current = uploadResult;

    openModal();

    setCropState({
      aspect: 1,
      base64File: {
        base64: removeBase64Meta(uploadResult.base64),
        fileName: uploadResult.name,
        fileType: uploadResult.type as FileType,
      },
      x: 0,
      y: 0,
      zoom: 1,
    });
  };

  const saveImage = (newCropState: CropState | null) => {
    if (!newCropState) return;

    setCropState(newCropState);

    setValue(name, newCropState.base64File, {
      shouldValidate: formState.isSubmitted,
      shouldDirty: true,
    });

    closeModal();
  };

  const handleUploadError = (err: UploadError) => {
    if (err.message === 'accept') {
      setUploadError({
        alertType: 'file-type-not-allowed',
      });
      return;
    }

    const error = imageMeetsCriteria(LOGO_CRITERIA, {
      height: err.height,
      width: err.width,
      size: err.size,
    });

    setUploadError(error);
  };

  const errorTx =
    getObjectValue<any>(formState.errors, name)?.message ??
    getObjectValue<any>(formState.errors, `${name}.base64`)?.message ??
    getObjectValue<any>(formState.errors, `${name}.fileName`)?.message ??
    getObjectValue<any>(formState.errors, `${name}.fileType`)?.message;

  return (
    <Box maxWidth="100%" flexAlign="center" flexWrap="wrap" flex gap={32}>
      <ImageCropper
        onClose={closeModal}
        isOpen={isOpen}
        onSave={saveImage}
        cropState={cropState}
        closeTx="button.close"
        saveTx="button.save"
      />

      <Box maxWidth="100%" flex flexDirection="column" gap={8}>
        <Upload
          accept={Object.values(FileType).join(',')}
          buttonTx="button.upload"
          onError={handleUploadError}
          onUpload={handleUpload}
          src={constructBase64(base64File)}
          aspectRatio="1"
          titleTx="label.upload.min-size"
          {...LOGO_CRITERIA}
          {...rest}
        />

        {uploadError ? (
          <Text
            textAlign="center"
            color="errorLabel"
            tx={getAlert(uploadError.alertType).subtitleTx}
            txArgs={uploadError.args}
            variant="bodyMd"
          />
        ) : isTxString(errorTx) ? (
          <Text
            textAlign="center"
            color="errorLabel"
            tx={errorTx}
            variant="bodyMd"
          />
        ) : null}
      </Box>
    </Box>
  );
}
