import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  CompanyProfile,
  CompanyProfileDraft,
  CreateCompanyProfile,
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
  UpdateCompanyProfileDraftParams,
  UpdateCompanyProfileIndexes,
  UpdateCompanyProfileParams,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import {
  CompanyProfileDraftDto,
  CompanyProfileDto,
  CreateCompanyProfileDraftResponseDto,
  CreateCompanyProfileResponseDto,
  PartialCompanyProfileDraftDto,
  PartialCompanyProfileDto,
} from './company-profiles.dto';
import {
  createCompanyProfileDraftResponseMapper,
  createCompanyProfileParamsMapper,
  createCompanyProfileResponseMapper,
  getCompanyProfileDraftResponseMapper,
  getCompanyProfileResponseMapper,
  partialCompanyProfileDraftMapper,
  partialCompanyProfileMapper,
  updateCompanyProfileDraftParamsMapper,
  updateCompanyProfileIndexesParamsMapper,
  updateCompanyProfileParamsMapper,
} from './company-profiles.mappers';

export const createCompanyProfileDraft = async (): Promise<
  GeneralApiResultWithData<string>
> => {
  const res = await apisauce.post<CreateCompanyProfileDraftResponseDto, any>(
    '/v2/company-profiles/drafts',
  );

  if (!res.ok) {
    Logger.warning('createCompanyProfileDraft', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = createCompanyProfileDraftResponseMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createCompanyProfileDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyProfileDrafts = async (): Promise<
  GeneralApiResultWithData<PartialCompanyProfileDraft[]>
> => {
  const res = await apisauce.get<PartialCompanyProfileDraftDto[], any>(
    '/v2/company-profiles/drafts',
  );

  if (!res.ok) {
    Logger.warning('getCompanyProfileDrafts', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialCompanyProfileDraftMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyProfileDrafts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyProfileDraft = async (
  companyProfileDraftKey: string,
): Promise<GeneralApiResultWithData<CompanyProfileDraft>> => {
  const res = await apisauce.get<CompanyProfileDraftDto, any>(
    `/v2/company-profiles/drafts/${companyProfileDraftKey}`,
  );

  if (!res.ok) {
    Logger.warning('getCompanyProfileDraft', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await getCompanyProfileDraftResponseMapper(res.data);

    if (!data) throw data;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyProfileDraft', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCompanyProfileDraft = async (
  updateCompanyProfileDraftParams: UpdateCompanyProfileDraftParams,
): Promise<GeneralApiResult> => {
  const { companyProfileDraftKey, updateCompanyProfileDraft } =
    updateCompanyProfileDraftParams;

  const res = await apisauce.patch<undefined, any>(
    `/v2/company-profiles/drafts/${companyProfileDraftKey}`,
    updateCompanyProfileDraftParamsMapper(updateCompanyProfileDraft),
  );

  if (!res.ok) {
    Logger.warning('updateCompanyProfileDraft', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteCompanyProfileDraft = async (
  companyProfileDraftKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<undefined, any>(
    `/v2/company-profiles/drafts/${companyProfileDraftKey}`,
  );

  if (!res.ok) {
    Logger.warning('deleteCompanyProfileDraft', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createCompanyProfile = async (
  createCompanyProfile: CreateCompanyProfile,
): Promise<GeneralApiResultWithData<string>> => {
  const params = createCompanyProfileParamsMapper(createCompanyProfile);

  const res = await apisauce.post<CreateCompanyProfileResponseDto, any>(
    '/v2/company-profiles',
    params,
  );

  if (!res.ok) {
    Logger.warning('createCompanyProfile', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = createCompanyProfileResponseMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createCompanyProfile', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyProfiles = async (): Promise<
  GeneralApiResultWithData<PartialCompanyProfile[]>
> => {
  const res = await apisauce.get<PartialCompanyProfileDto[], any>(
    '/v2/company-profiles',
  );

  if (!res.ok) {
    Logger.warning('getCompanyProfiles', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(partialCompanyProfileMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyProfiles', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyProfile = async (
  companyProfileKey: string,
): Promise<GeneralApiResultWithData<CompanyProfile>> => {
  const res = await apisauce.get<CompanyProfileDto, any>(
    `/v2/company-profiles/${companyProfileKey}`,
  );

  if (!res.ok) {
    Logger.warning('getCompanyProfile', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await getCompanyProfileResponseMapper(res.data);

    if (!data) throw data;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyProfile', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCompanyProfile = async (
  updateCompanyProfileParams: UpdateCompanyProfileParams,
): Promise<GeneralApiResult> => {
  const { companyProfileKey, updateCompanyProfile } =
    updateCompanyProfileParams;

  const res = await apisauce.patch<undefined, any>(
    `/v2/company-profiles/${companyProfileKey}`,
    updateCompanyProfileParamsMapper(updateCompanyProfile),
  );

  if (!res.ok) {
    Logger.warning('updateCompanyProfile', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteCompanyProfile = async (
  companyProfileKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<undefined, any>(
    `/v2/company-profiles/${companyProfileKey}`,
  );

  if (!res.ok) {
    Logger.warning('deleteCompanyProfile', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const updateCompanyProfileIndexes = async (
  updateCompanyProfileIndexes: UpdateCompanyProfileIndexes,
): Promise<GeneralApiResult> => {
  const res = await apisauce.put<undefined, any>(
    '/v2/company-profiles/indexes',
    updateCompanyProfileIndexesParamsMapper(updateCompanyProfileIndexes),
  );

  if (!res.ok) {
    Logger.warning('updateCompanyProfileIndexes', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
