import {
  Box,
  Button,
  Icon,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSideAlt,
  Text,
} from '@orbiapp/components';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { NotOnboardedToolbar } from '../../../components';
import { firebase } from '../../../services';
import {
  AuthStateSelector,
  sendEmailVerificationThunk,
  useDispatch,
  useSelector,
} from '../../../store';

function EmailNotVerifiedContent() {
  const dispatch = useDispatch();

  const authStatus = useSelector(AuthStateSelector.selectStatus);

  const email = getAuth(firebase).currentUser?.email ?? '';

  const requestVerifyEmailMail = () =>
    dispatch(sendEmailVerificationThunk(email));

  return (
    <React.Fragment>
      <Icon
        size={100}
        name="exclamation-triangle-solid"
        color="emailNotVerifiedIcon"
      />

      <Box flex flexDirection="column" flexAlign="center" gap={16}>
        <Text
          textAlign="center"
          variant="titleMd"
          tx="label.auth.email-not-verified.title"
          color="signedOutPagesTitleColor"
        />
        <Text
          textAlign="center"
          variant="bodyMd"
          tx="label.auth.email-not-verified.subtitle"
          txArgs={{ email }}
          color="signedOutPagesTextColor"
        />
      </Box>

      <Button
        variant="primary"
        isLoading={authStatus === 'pending'}
        tx="button.auth.re-send-mail"
        onClick={requestVerifyEmailMail}
        large
      />
    </React.Fragment>
  );
}

export function EmailNotVerified() {
  const emailVerified = useSelector(AuthStateSelector.accountVerified);

  if (emailVerified) {
    return <Navigate to="/insights" replace />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <SplitScreenContainer>
        <SplitScreenLeftSideAlt
          titleTx="label.auth.orbi-network.title"
          subtitleTx="label.auth.orbi-network.subtitle"
        />

        <SplitScreenContentContainer>
          <EmailNotVerifiedContent />
        </SplitScreenContentContainer>
      </SplitScreenContainer>
    </React.Fragment>
  );
}
