import { joiResolver } from '@hookform/resolvers/joi';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  ContentSidebarContentContainer,
  ControlledDatePicker,
  ControlledTextarea,
  IconButton,
  PrintQrCode,
  SolidIconButton,
  Spinner,
  Status,
  Text,
  Tooltip,
  TrailingInputBox,
  TrailingTextareaBox,
  WyiswygEditContainer,
  WysiwygPreviewContainer,
  WysiwygProvider,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  isTxString,
  numberFormatter,
  parseTimestamp,
  translate,
  useWysiwyg,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH,
  COMPANY_QR_CODE_NAME_MAX_LENGTH,
  CompanyQr,
  EditCompanyQrDescriptionForm,
  EditCompanyQrNameForm,
  EditCompanyQrStartDateForm,
  UpdateCompanyQrDescriptionValidation,
  UpdateCompanyQrNameValidation,
  UpdateCompanyQrStartDateValidation,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  CompanyQrSelector,
  updateCompanyQrThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import {
  getConnectQrCodeStatus,
  getOptionalLabelText,
} from '../../../../../../utils';
import { TitleInput } from '../../title-input';

function EditQrCodeName() {
  const name = useSelector(CompanyQrSelector.selectCompanyQrName);

  const dispatch = useDispatch();

  const formMethods = useForm<EditCompanyQrNameForm>({
    resolver: joiResolver(UpdateCompanyQrNameValidation),
    defaultValues: {
      name: name ?? '',
    },
  });

  const saveQrCodeName = formMethods.handleSubmit(
    async (data) => {
      if (!formMethods.formState.isDirty) return;

      const res = await dispatch(
        updateCompanyQrThunk({
          name: data.name,
        }),
      );

      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset({
          name: data.name,
        });
      }
    },
    (err) => {
      Logger.warning('editCompanyQrCodeName Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      name: name ?? '',
    });
  }, [name, formMethods]);

  return (
    <TitleInput
      placeholder={translate('label.connect.untitled-qr-code')}
      maxLength={COMPANY_QR_CODE_NAME_MAX_LENGTH}
      errorTx={
        isTxString(formMethods.formState.errors.name?.message)
          ? formMethods.formState.errors.name?.message
          : undefined
      }
      {...formMethods.register('name', {
        onBlur: saveQrCodeName,
      })}
    />
  );
}

function EditQrCodeStartDate() {
  const startDate = useSelector(CompanyQrSelector.selectCompanyQrStartDate);
  const updateCompanyQrStatus = useSelector(CompanyQrSelector.selectStatus);

  const dispatch = useDispatch();

  const wysiwyg = useWysiwyg();

  const formMethods = useForm<EditCompanyQrStartDateForm>({
    resolver: joiResolver(UpdateCompanyQrStartDateValidation),
    defaultValues: {
      startDate: startDate ?? Date.now(),
    },
  });

  if (wysiwyg.mode === 'preview') {
    return (
      <WysiwygPreviewContainer
        px={0}
        py={0}
        labelTx="label.connect.start-date"
        value={parseTimestamp(startDate ?? Date.now(), 'DD MMM YYYY, HH:mm')}
        actions={
          startDate && startDate > Date.now() ? (
            <Tooltip placement="left" titleTx="label.connect.edit-start-date">
              <SolidIconButton
                aria-label={translate('label.connect.edit-start-date')}
                onClick={wysiwyg.setModeToEdit}
                icon="pencil-square-outline"
              />
            </Tooltip>
          ) : undefined
        }
      />
    );
  }

  const resetQrCodeStartDate = () => {
    formMethods.reset();
  };

  const saveQrCodeStartDate = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(
        updateCompanyQrThunk({
          startDate: data.startDate,
        }),
      );

      if (res.meta.requestStatus === 'fulfilled') {
        wysiwyg.setModeToPreview();
        formMethods.reset({ startDate: data.startDate });
      }
    },
    (err) => {
      Logger.warning('editCompanyQrCodeStartDate Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const isLoading = updateCompanyQrStatus === 'pending';

  return (
    <WyiswygEditContainer
      disableEnterPreviewMode={formMethods.formState.isDirty}
    >
      <FormProvider {...formMethods}>
        <ControlledDatePicker
          type="datetime-local"
          labelTx="label.connect.start-date"
          name="startDate"
          min={parseTimestamp(Date.now(), 'YYYY-MM-DDTHH:mm')}
          trailingElement={
            <TrailingInputBox>
              <Tooltip
                placement="left"
                titleTx="label.connect.reset-start-date"
              >
                <IconButton
                  disabled={isLoading}
                  icon="undo"
                  onClick={resetQrCodeStartDate}
                  aria-label={translate('label.connect.reset-start-date')}
                />
              </Tooltip>
              <Tooltip placement="left" titleTx="label.connect.save-start-date">
                <IconButton
                  icon="check"
                  isLoading={isLoading}
                  onClick={saveQrCodeStartDate}
                  aria-label={translate('label.connect.save-start-date')}
                />
              </Tooltip>
            </TrailingInputBox>
          }
        />
      </FormProvider>
    </WyiswygEditContainer>
  );
}

function EditQrCodeDescription() {
  const description = useSelector(CompanyQrSelector.selectCompanyQrDescription);
  const updateCompanyQrStatus = useSelector(CompanyQrSelector.selectStatus);

  const dispatch = useDispatch();

  const wysiwyg = useWysiwyg();

  const formMethods = useForm<EditCompanyQrDescriptionForm>({
    resolver: joiResolver(UpdateCompanyQrDescriptionValidation),
    defaultValues: {
      description: description ?? '',
    },
  });

  if (wysiwyg.mode === 'preview') {
    return (
      <WysiwygPreviewContainer
        px={0}
        py={0}
        labelTx="label.connect.description"
        value={description ?? undefined}
        emptyTextTx="label.connect.no-description-provided"
        actions={
          <Tooltip placement="left" titleTx="label.connect.edit-description">
            <SolidIconButton
              aria-label={translate('label.connect.edit-description')}
              onClick={wysiwyg.setModeToEdit}
              icon="pencil-square-outline"
            />
          </Tooltip>
        }
      />
    );
  }

  const resetDescription = () => {
    formMethods.reset();
  };

  const saveDescription = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(
        updateCompanyQrThunk({
          description: data.description,
        }),
      );

      if (res.meta.requestStatus === 'fulfilled') {
        wysiwyg.setModeToPreview();
        formMethods.reset({ description: data.description });
      }
    },
    (err) => {
      Logger.warning('editCompanyQrCodeDescription Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const isLoading = updateCompanyQrStatus === 'pending';

  return (
    <WyiswygEditContainer
      disableEnterPreviewMode={formMethods.formState.isDirty}
    >
      <FormProvider {...formMethods}>
        <ControlledTextarea
          label={getOptionalLabelText('label.connect.description')}
          name="description"
          maxLength={COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH}
          minHeight={85}
          trailingElement={
            <TrailingTextareaBox>
              <Tooltip
                placement="left"
                titleTx="label.connect.reset-description"
              >
                <IconButton
                  disabled={isLoading}
                  icon="undo"
                  onClick={resetDescription}
                  aria-label={translate('label.connect.reset-description')}
                />
              </Tooltip>

              <Tooltip
                placement="left"
                titleTx="label.connect.save-description"
              >
                <IconButton
                  icon="check"
                  isLoading={isLoading}
                  onClick={saveDescription}
                  aria-label={translate('label.connect.save-description')}
                />
              </Tooltip>
            </TrailingTextareaBox>
          }
        />
      </FormProvider>
    </WyiswygEditContainer>
  );
}

function ViewQrCodeSidebarContent(props: CompanyQr) {
  const { endDate, startDate, editMeta, connectCount, scanCount } = props;

  return (
    <ContentSidebarContentContainer>
      <EditQrCodeName />

      <Box flexWrap flexAlign="center" flex gap={16}>
        <Box flexAlign="center" flex gap={16}>
          <Avatar
            width={24}
            height={24}
            fallbackLetter={editMeta.createdBy?.firstName[0]}
            variant={
              editMeta.createdBy
                ? getAvatarVariantFromString(editMeta.createdBy.userKey)
                : undefined
            }
            src={
              editMeta.createdBy?.profilePicture?.thumbnail64.url ?? undefined
            }
          />

          <Box>
            <Text tx="label.connect.created-by" variant="bodySm" />
            {editMeta.createdBy ? (
              <Text
                text={`${editMeta.createdBy.firstName} ${editMeta.createdBy.lastName}`}
                variant="bodySm"
                color="connectQrCodeCreatedBySubtitle"
              />
            ) : (
              <Text
                tx="label.connect.unknown-user"
                variant="bodySm"
                color="connectQrCodeCreatedBySubtitle"
              />
            )}
          </Box>
        </Box>

        <Status {...getConnectQrCodeStatus(startDate, endDate)} />
      </Box>

      <WysiwygProvider>
        <EditQrCodeStartDate />
      </WysiwygProvider>

      <Box flex flexDirection="column" gap={4}>
        <Text
          tx="label.connect.end-date"
          variant="bodySm"
          color="connectViewUserListItemTitle"
        />

        <Text text={parseTimestamp(endDate, 'DD MMM YYYY, HH:mm')} />
      </Box>

      <WysiwygProvider>
        <EditQrCodeDescription />
      </WysiwygProvider>

      <PrintQrCode
        expandTx="label.general.expand"
        printTx="label.general.print"
      />

      <Box gap={16} flex>
        <Card py={8} px={16} flexGrow={1} width="100%">
          <CardHeader
            titleTx="label.connect.scans"
            subtitleText={numberFormatter.format(scanCount)}
          />
        </Card>
        <Card py={8} px={16} flexGrow={1} width="100%">
          <CardHeader
            titleTx="label.connect.talent-acquired"
            subtitleText={numberFormatter.format(connectCount)}
          />
        </Card>
      </Box>
    </ContentSidebarContentContainer>
  );
}

export function ViewQrCodeSidebar() {
  const companyQr = useSelector(CompanyQrSelector.selectCompanyQr);
  const companyQrStatus = useSelector(CompanyQrSelector.selectStatus);

  if (companyQrStatus === 'pending') {
    return (
      <ContentSidebarContentContainer>
        <Box py={32} flex flexJustify="center">
          <Spinner />
        </Box>
      </ContentSidebarContentContainer>
    );
  }

  if (!companyQr) {
    return null;
  }

  return <ViewQrCodeSidebarContent {...companyQr} />;
}
