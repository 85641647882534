import { joiResolver } from '@hookform/resolvers/joi';
import {
  Avatar,
  Box,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledDatePicker,
  ControlledTextarea,
  SolidIconButton,
  Text,
  Time,
  Tooltip,
  flattenFieldErrorsObject,
  isTxString,
  parseTimestamp,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH,
  COMPANY_QR_CODE_NAME_MAX_LENGTH,
  CreateCompanyQr,
  CreateCompanyQrValidation,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  AccountSelector,
  CreateCompanyQrSelector,
  createCompanyQrThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { getOptionalLabelText } from '../../../../../../utils';
import { AvailableQrCodesStatusChip } from '../../status-chips';
import { TitleInput } from '../../title-input';
import { ConnectSidebarContext } from '../sidebar.context';

export function CreateQrCodeSidebar() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const createCompanyQrStatus = useSelector(
    CreateCompanyQrSelector.selectStatus,
  );
  const dispatch = useDispatch();

  const { closeSidebar } = React.useContext(ConnectSidebarContext);

  const formMethods = useForm<CreateCompanyQr>({
    resolver: joiResolver(CreateCompanyQrValidation),
    defaultValues: {
      description: '',
      name: '',
      startDate: Date.now() + Time.Day * 7,
    },
  });

  const createCompanyQr = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(createCompanyQrThunk(data));

      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset();
        closeSidebar();
      }
    },
    (err) => {
      Logger.warning('createCompanyQr Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    window.setTimeout(() => {
      formMethods.setFocus('name');
    }, 150);
  }, [formMethods]);

  return (
    <React.Fragment>
      <ContentSidebarContentContainer>
        <FormProvider {...formMethods}>
          <Box flex flexJustify="between" gap={16}>
            <TitleInput
              placeholder={translate('label.connect.untitled-qr-code')}
              maxLength={COMPANY_QR_CODE_NAME_MAX_LENGTH}
              errorTx={
                isTxString(formMethods.formState.errors.name?.message)
                  ? formMethods.formState.errors.name?.message
                  : undefined
              }
              {...formMethods.register('name')}
            />

            <Tooltip placement="left" titleTx="label.connect.create-qr-code">
              <SolidIconButton
                icon="check-circle-outline"
                isLoading={createCompanyQrStatus === 'pending'}
                onClick={createCompanyQr}
                aria-label={translate('label.connect.create-qr-code')}
              />
            </Tooltip>
          </Box>

          <Box gap={16} flexJustify="between" flexDirection="column" flex>
            <Box flexAlign="center" flex gap={16}>
              <Avatar
                width={24}
                height={24}
                fallbackLetter={fullName?.[0]}
                src={profilePicture?.thumbnail64.url ?? undefined}
              />

              <Box>
                <Text tx="label.connect.created-by" variant="bodySm" />
                <Text
                  text={fullName ?? translate('label.connect.unknown-user')}
                  variant="bodySm"
                  color="connectQrCodeCreatedBySubtitle"
                />
              </Box>
            </Box>
          </Box>

          <ControlledDatePicker
            labelTx="label.connect.activation-date"
            type="datetime-local"
            name="startDate"
            helperTx="label.connect.activation-date-helper-text"
            min={parseTimestamp(Date.now(), 'YYYY-MM-DDTHH:mm')}
          />

          <ControlledTextarea
            label={getOptionalLabelText('label.connect.description')}
            name="description"
            maxLength={COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH}
            minHeight={85}
          />
        </FormProvider>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer>
        <AvailableQrCodesStatusChip />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}
