import { account } from './account';
import { auth } from './auth';
import { company } from './company';
import { companyAds } from './company-ads';
import { countries } from './countries';
import { featureFlags } from './feature-flags';
import { insights } from './insights';
import { locations } from './locations';
import { offers } from './offers';
import { search } from './search';

export const v1 = {
  auth,
  featureFlags,
  account,
  company,
  insights,
  companyAds,
  offers,
  locations,
  search,
  countries,
};
