import {
  CreateOfferForm,
  Offer,
  OfferCategory,
  PartialOffer,
} from '../../../../../models';
import {
  CreateOfferDto,
  OfferCategoryDto,
  OfferDto,
  PartialOfferDto,
} from './offers.dto';

export function offerMapper(offerDto: OfferDto): Offer {
  const base = {
    offerKey: offerDto.offerKey,

    editMeta: offerDto.editMeta,

    offerCategory: offerDto.offerCategory,
    title: offerDto.title,
    description: offerDto.description,
    coverImage: offerDto.coverImage,

    startDate: offerDto.startDate,
    endDate: offerDto.endDate,
    contactName: offerDto.contactName,
    contactEmail: offerDto.contactEmail,

    disabledAt: offerDto.disabledAt,

    locations: offerDto.locations,
    requireStudentVerification: offerDto.requireStudentVerification,

    countryKeys: offerDto.countryKeys,
  };

  if (offerDto.type === 'online') {
    return {
      ...base,
      type: 'online',
      link: offerDto.link,
      code: offerDto.code,
    };
  }

  return {
    ...base,
    type: 'consumable',
    consumeWaitMinutes: offerDto.consumeWaitMinutes,
  };
}

export function partialOfferMapper(
  partialOfferDto: PartialOfferDto,
): PartialOffer {
  return {
    contactName: partialOfferDto.contactName,
    disabledAt: partialOfferDto.disabledAt,
    editMeta: partialOfferDto.editMeta,
    endDate: partialOfferDto.endDate,
    offerKey: partialOfferDto.offerKey,
    startDate: partialOfferDto.startDate,
    title: partialOfferDto.title,
  };
}

export function createOfferMapper(
  createOffer: CreateOfferForm,
): CreateOfferDto {
  return {
    offerCategoryKey: createOffer.offerCategoryKey,
    title: createOffer.title,
    description: createOffer.description,
    coverImage: createOffer.coverImage!,

    startDate: createOffer.startDate,
    endDate: createOffer.endDate,
    contactName: createOffer.contactName,
    contactEmail: createOffer.contactEmail,

    code: createOffer.code,
    link: createOffer.link,

    files: createOffer.files,

    isConsumable: createOffer.isConsumable,
    consumeWaitMinutes: createOffer.consumeWaitMinutes,

    offerDraftKey: createOffer.offerDraftKey,

    locations: createOffer.locations,
    requireStudentVerification: createOffer.requireStudentVerification,

    countryKeys: createOffer.countryKeys,
  };
}

export function offerCategoryMapper(
  offerCategoryDto: OfferCategoryDto,
): OfferCategory {
  return {
    emoji: offerCategoryDto.emoji,
    name: offerCategoryDto.name,
    offerCategoryKey: offerCategoryDto.offerCategoryKey,
  };
}
