import {
  LinearProgressVariant,
  StatusVariant,
  Time,
  TxArgs,
  parseTimestamp,
} from '@orbiapp/components';

export function getConnectQrCodeStatus(
  startDate: number,
  endDate: number,
): {
  tx: TxString;
  variant: StatusVariant;
  txArgs?: TxArgs;
} {
  const now = Date.now();

  if (now > endDate) {
    return {
      tx: 'label.qr-code-status.expired',
      variant: 'error',
    };
  }

  if (now < startDate) {
    return {
      tx: 'label.qr-code-status.scheduled',
      variant: 'info',
      txArgs: {
        date: parseTimestamp(startDate, 'DD MMM YYYY, HH:mm'),
      },
    };
  }

  if (now > endDate - Time.Day) {
    return {
      tx: 'label.qr-code-status.expires-soon',
      variant: 'warning',
    };
  }

  return {
    tx: 'label.qr-code-status.live',
    variant: 'success',
  };
}

export function getConnectConversionStatus(
  conversion: number,
): LinearProgressVariant {
  /**
   * > 70 = success
   * > 40 = warning
   * < 40 = danger
   */

  if (conversion > 70) {
    return 'success';
  }

  if (conversion > 40) {
    return 'warning';
  }

  return 'danger';
}
