import {
  Box,
  Icon,
  Link,
  Status,
  StatusVariant,
  Text,
  numberFormatter,
} from '@orbiapp/components';

import { CONTACT_CUSTOMER_SUCCESS_URL } from '../../../../../constants';
import { ModuleSettingsSelector, useSelector } from '../../../../../store';

export function AvailableQrCodesStatusChip() {
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  if (!connectModule || connectModule.maxQrCodeCount === null) {
    return null;
  }

  const remainingQrCodeCount = Math.max(
    0,
    connectModule.maxQrCodeCount - connectModule.usedQrCodeCount,
  );

  let variant: StatusVariant = 'success';
  if (remainingQrCodeCount === 0) {
    variant = 'error';
  }
  if (remainingQrCodeCount === 1) {
    variant = 'warning';
  }

  return (
    <Box flex flexAlign="center" gap={8}>
      <Status
        tx="label.connect.qr-codes-available"
        txArgs={{ count: numberFormatter.format(remainingQrCodeCount) }}
        variant={variant}
      />

      <Link
        href={CONTACT_CUSTOMER_SUCCESS_URL}
        small
        tx="label.connect.contact-sales"
        variant="secondary"
      />
    </Box>
  );
}

export function ConnectLicenseStatusChip() {
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  if (!connectModule?.talentPoolIsEnabled) {
    return (
      <Status
        flexAlign="center"
        gap={8}
        variant="info"
        data-testid="connect-talent-pool-license-status"
      >
        <Icon name="lock-closed-solid" color="connectTalentPoolStatusIcon" />

        <Text
          variant="bodySm"
          color="connectTalentPoolStatusColor"
          tx="label.connect.no-license"
        />

        <Link
          href={CONTACT_CUSTOMER_SUCCESS_URL}
          small
          tx="label.connect.contact-sales"
          variant="tertiary"
        />
      </Status>
    );
  }

  return null;
}
