import { Dataset, isNumber, stringEllipsis } from '@orbiapp/components';
import dayjs from 'dayjs';

import {
  InsightsCompareInterval,
  InsightsInterval,
  InsightsMetric,
  InsightsRange,
  TimeSeriesInsightsItem,
  TimeSeriesInsightsItems,
} from '../../models';
import { getInsightDates } from './insights-dates';
import {
  GetCustomInsightsPoints,
  GetInsightsPointsParams,
  GetPredefinedInsightsPoints,
} from './insights.types';

export function getInsightsIntervalTx(
  interval: InsightsInterval | InsightsCompareInterval,
): TxString {
  switch (interval) {
    case 'last_30_days':
      return 'label.insights-data-range.last-30-days';

    case 'last_365_days':
      return 'label.insights-data-range.last-365-days';

    case 'last_7_days':
      return 'label.insights-data-range.last-7-days';

    case 'last_90_days':
      return 'label.insights-data-range.last-90-days';

    case 'previous_month':
      return 'label.insights-data-range.previous-month';

    case 'previous_quarter':
      return 'label.insights-data-range.previous-quarter';

    case 'previous_year':
      return 'label.insights-data-range.previous-year';

    case 'today':
      return 'label.insights-data-range.today';

    case 'previous_period':
      return 'label.insights-data-range.previous-period';

    default:
      return 'label.insights-data-range.custom';
  }
}

export function getInsightsMetricDescriptionTx(metric: InsightsMetric) {
  switch (metric) {
    case 'impressions':
      return 'label.insights.total-impressions-description';

    case 'engagement':
      return 'label.insights.total-engagements-description';

    case 'views':
      return 'label.insights.total-views-description';
  }
}

export function getProductLabelTx(
  type: TimeSeriesInsightsItem['type'],
): TxString {
  switch (type) {
    case 'company_profile':
      return 'label.product-type.profile';

    case 'job':
      return 'label.product-type.job';

    case 'company_ad':
      return 'label.product-type.booster';

    case 'offer':
      return 'label.product-type.offer';

    default:
      return 'label.product-type.total';
  }
}

export function getInsightsMetricTx(insightsMetric: InsightsMetric): TxString {
  switch (insightsMetric) {
    case 'engagement':
      return 'label.insights.engagement';

    case 'impressions':
      return 'label.insights.impressions';

    case 'views':
      return 'label.insights.views';
  }
}

function getCustomInsightDates(params: GetCustomInsightsPoints) {
  const diffDays = params.toDayjs.diff(params.fromDayjs, 'days');

  if (diffDays !== 1) {
    const timestamps = [];
    for (let i = 0; i <= diffDays; i++) {
      timestamps.push(params.fromDayjs.startOf('day').add(i, 'day').valueOf());
    }

    return timestamps;
  }

  const startOfDay = params.fromDayjs.startOf('day');
  const endOfDay = params.toDayjs.startOf('day');
  const diffHours = endOfDay.diff(startOfDay, 'hours');

  const today = [];

  for (let i = 1; i < diffHours; i++) {
    const date = startOfDay.add(i, 'hour');

    today.push(date.valueOf());
  }

  return [
    startOfDay.valueOf(),
    ...today,
    endOfDay.valueOf(),
    endOfDay.add(1, 'hour').startOf('hour').valueOf(),
  ];
}

function getCompareInsightsDayjsInstance(
  params: GetPredefinedInsightsPoints,
): dayjs.Dayjs {
  if (params.compare === 'previous_year') {
    return params.dayjs.subtract(1, 'year');
  }

  switch (params.interval) {
    case 'today':
      return params.dayjs.subtract(1, 'day');

    case 'last_7_days':
      return params.dayjs.subtract(6, 'days');

    case 'last_30_days':
      return params.dayjs.subtract(29, 'days');

    case 'last_90_days':
      return params.dayjs.subtract(89, 'days');

    case 'last_365_days':
      return params.dayjs.subtract(364, 'days');

    case 'previous_month':
      return params.dayjs.subtract(1, 'month');

    case 'previous_quarter':
      return params.dayjs.subtract(1, 'quarter');

    case 'previous_year':
      return params.dayjs.subtract(1, 'year');
  }
}

export function getInsightsPoints(params: GetInsightsPointsParams) {
  if (params.interval !== 'custom') {
    const mainPeriod = getInsightDates(params);

    if (!params.compare) {
      return { mainPeriod, comparedPeriod: null };
    }

    const comparedPeriod = getInsightDates({
      ...params,
      dayjs: getCompareInsightsDayjsInstance(params),
    });

    return { mainPeriod, comparedPeriod };
  }

  const mainPeriod = getCustomInsightDates(params);

  if (!params.compare) {
    return { mainPeriod, comparedPeriod: null };
  }

  if (params.compare === 'previous_year') {
    const comparedPeriod = getInsightDates({
      dayjs: params.dayjs,
      compare: null,
      interval: 'previous_year',
    });

    return { mainPeriod, comparedPeriod };
  }

  const comparedPeriod = getCustomInsightDates(params);

  return { mainPeriod, comparedPeriod };
}

function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getInsightsPointsParams(
  params: InsightsRange,
): GetInsightsPointsParams {
  const timezone = getBrowserTimezone();

  if (params.interval === 'custom') {
    return {
      dayjs: dayjs.tz(undefined, timezone),
      interval: params.interval,
      compare: params.compare,
      fromDayjs: dayjs.tz(params.fromDate, timezone),
      toDayjs: dayjs.tz(params.toDate, timezone),
    };
  }

  return {
    dayjs: dayjs.tz(undefined, timezone),
    interval: params.interval,
    compare: params.compare,
  };
}

function getChartXLabelsDateFormat(interval: InsightsRange['interval']) {
  switch (interval) {
    case 'last_7_days':
    case 'last_30_days':
    case 'last_90_days':
    case 'previous_month':
      return 'D MMM';

    case 'last_365_days':
    case 'previous_quarter':
    case 'previous_year':
      return 'MMM';

    case 'today':
      return 'HH:00';
  }
}

function getDatasetLabels(timestamps: number[], range: InsightsRange) {
  if (range.interval !== 'custom') {
    return timestamps.map((timestamp) =>
      dayjs(timestamp).format(getChartXLabelsDateFormat(range.interval)),
    );
  }

  const diffDays = dayjs(range.toDate).diff(dayjs(range.fromDate), 'days');

  if (diffDays === 1) {
    return timestamps.map((timestamp) =>
      dayjs(timestamp).format(getChartXLabelsDateFormat('today')),
    );
  }

  if (diffDays < 7) {
    return timestamps.map((timestamp) =>
      dayjs(timestamp).format(getChartXLabelsDateFormat('last_7_days')),
    );
  }

  if (diffDays < 30) {
    return timestamps.map((timestamp) =>
      dayjs(timestamp).format(getChartXLabelsDateFormat('last_30_days')),
    );
  }

  if (diffDays < 90) {
    return timestamps.map((timestamp) =>
      dayjs(timestamp).format(getChartXLabelsDateFormat('last_90_days')),
    );
  }

  return timestamps.map((timestamp) =>
    dayjs(timestamp).format(getChartXLabelsDateFormat('last_365_days')),
  );
}

export function getLabelsAndDatasets(
  timeSeriesInsightsItems: TimeSeriesInsightsItems[],
  insightsDataRange: InsightsRange,
): {
  datasets: Dataset[];
  labels: string[];
  comparedLabels?: string[];
} {
  let datasets: Dataset[] = [];

  const items = timeSeriesInsightsItems.flatMap((item) => item.items);
  const comparedItems = timeSeriesInsightsItems.flatMap(
    (item) =>
      item.itemsCompared?.filter((item) => item.points.length > 0) ?? [],
  );

  const timestamps = Array.from(
    new Set(
      timeSeriesInsightsItems.flatMap((timeSeriesInsightsItem) =>
        timeSeriesInsightsItem.items.flatMap((item) =>
          item.points.map((point) => point.timestamp),
        ),
      ),
    ),
  );
  const comparedTimestamps = Array.from(
    new Set(
      timeSeriesInsightsItems
        .flatMap((timeSeriesInsightsItem) =>
          timeSeriesInsightsItem.itemsCompared?.flatMap((item) =>
            item.points.map((point) => point.timestamp),
          ),
        )
        .filter(isNumber),
    ),
  );

  items.forEach((item, index) => {
    let sliceIndex = item.points.length;
    if (insightsDataRange.interval === 'today') {
      const currentHours = dayjs().tz(getBrowserTimezone()).hour();

      for (let i = 0; i < item.points.length; i++) {
        const timestampHour = dayjs
          .tz(item.points[i].timestamp, getBrowserTimezone())
          .hour();

        if (timestampHour === currentHours) {
          sliceIndex = i;
          break;
        }
      }
    }

    const data = item.points.map((point) => point.value).slice(0, sliceIndex);

    if (item.type === 'total') {
      datasets.push({ data, labelTx: 'label.select-products-menu.total' });
    } else {
      datasets.push({ data, label: stringEllipsis(item.label, 20) });
    }

    if (comparedItems.length > 0) {
      const comparedItem = comparedItems[index];
      const data = comparedItem.points.map((point) => point.value);

      if (comparedItem.type === 'total') {
        datasets.push({
          data,
          labelTx: 'label.select-products-menu.total',
          isCompared: true,
        });
      } else {
        datasets.push({
          data,
          label: stringEllipsis(comparedItem.label, 20),
          isCompared: true,
        });
      }
    }
  });

  const labels = getDatasetLabels(timestamps, insightsDataRange);
  const comparedLabels = getDatasetLabels(
    comparedTimestamps,
    insightsDataRange,
  );

  return {
    datasets,
    labels,
    comparedLabels: comparedLabels.length ? comparedLabels : undefined,
  };
}
