import {
  Box,
  Checkbox,
  FormSection,
  FormSectionHeader,
  Icon,
  IconButton,
  InputChip,
  InputChips,
  LeadingInputBox,
  Menu,
  MenuItem,
  Text,
  Tooltip,
  TrailingInputBox,
  isTxString,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CompanyProfileForm,
  EmploymentType,
  employmentTypes,
} from '../../../../../models';
import { getEmploymentTypeTx } from '../../../../../utils';
import { ProfileContext } from './profile-form.context';

function EmploymentTypeMenuItem(props: {
  employmentType: EmploymentType;
  isChecked: boolean;
  toggleEmploymentType: () => void;
}) {
  const { employmentType, isChecked, toggleEmploymentType } = props;

  return (
    <MenuItem onClick={toggleEmploymentType} gap={8} flex flexAlign="center">
      <Checkbox onChange={toggleEmploymentType} checked={isChecked} />
      <Text
        as="span"
        tx={getEmploymentTypeTx(employmentType)}
        variant="bodyMd"
      />
    </MenuItem>
  );
}

export function EmploymentTypes() {
  const { saveField, formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const save = () => saveField('employmentTypes');

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CompanyProfileForm>();

  const [employmentTypesFiltered, setEmploymentTypesFiltered] = React.useState([
    ...employmentTypes,
  ]);

  const [searchValue, setSearchValue] = React.useState('');

  const clickOutsideRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu({
    clickOutsideRef,
    onClickOutside: isDraft ? save : undefined,
  });

  const closeMenu = () => {
    menuState.closeMenu();

    if (isDraft) {
      save();
    }
  };

  const selectedEmploymentTypes = watch('employmentTypes') ?? [];

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);

    setEmploymentTypesFiltered(
      employmentTypes.filter((employmentType) =>
        employmentType.includes(e.target.value),
      ),
    );
  };

  const removeChip = (id: number) => {
    const newValue = selectedEmploymentTypes.filter((_, index) => index !== id);

    setValue('employmentTypes', newValue?.length ? newValue : null, {
      shouldDirty: true,
    });

    if (!menuState.isOpen && isDraft) {
      save();
    }
  };

  const resetInput = () => {
    if (searchInputRef.current?.value.length) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }

    setEmploymentTypesFiltered([...employmentTypes]);
  };

  const chips: InputChip<number>[] = selectedEmploymentTypes.map(
    (employmentType, index) => ({
      id: index,
      tx: getEmploymentTypeTx(employmentType),
      maxWidth: 'unset',
    }),
  );

  const renderEmploymentType = (employmentType: EmploymentType) => {
    const isChecked = selectedEmploymentTypes.includes(employmentType);

    const toggleEmploymentType = () => {
      const newValue = isChecked
        ? selectedEmploymentTypes.filter(
            (_employmentType) => _employmentType !== employmentType,
          )
        : [...selectedEmploymentTypes, employmentType];

      if (!isChecked) {
        resetInput();
      }

      setValue('employmentTypes', newValue.length ? newValue : null, {
        shouldDirty: true,
      });
    };

    return (
      <EmploymentTypeMenuItem
        employmentType={employmentType}
        isChecked={isChecked}
        key={employmentType}
        toggleEmploymentType={toggleEmploymentType}
      />
    );
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.profile.employment-types.header"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          tx="label.profile.employment-types.description"
          variant="bodyMd"
          color="formSectionDescription"
        />
      </FormSectionHeader>

      <Box relative ref={clickOutsideRef} minHeight={106}>
        <InputChips
          ref={searchInputRef}
          hideErrorMessage={menuState.isOpen}
          onChange={handleInputChange}
          onFocus={menuState.openMenu}
          value={searchValue}
          maxChips={1000}
          showInput={menuState.isOpen || !!searchValue}
          defaultValue=""
          chips={chips}
          errorTx={
            isTxString(errors.employmentTypes?.message)
              ? errors.employmentTypes?.message
              : undefined
          }
          onRemoveChip={removeChip}
          leadingElement={
            <LeadingInputBox>
              <Icon name="magnifying-glass" />
            </LeadingInputBox>
          }
          trailingElement={
            <TrailingInputBox>
              {menuState.isOpen ? (
                <Tooltip placement="top" titleTx="label.tooltip.collapse">
                  <IconButton icon="chevron-up" onClick={closeMenu} />
                </Tooltip>
              ) : (
                <Tooltip placement="top" titleTx="label.tooltip.expand">
                  <IconButton
                    icon="chevron-down"
                    onClick={menuState.openMenu}
                  />
                </Tooltip>
              )}
            </TrailingInputBox>
          }
          labelTx="label.profile.employment-types.inputs.employment-type"
          menuElement={
            <Menu
              absolute
              isOpen={menuState.isOpen}
              maxHeight={360}
              ref={menuRef}
              mt={8}
              top="100%"
              width="100%"
            >
              {employmentTypesFiltered.length ? (
                employmentTypesFiltered.map(renderEmploymentType)
              ) : (
                <MenuItem gap={8} flex flexAlign="center">
                  <Text
                    as="span"
                    tx="placeholder.no-employment-type"
                    variant="bodyMd"
                  />
                </MenuItem>
              )}
            </Menu>
          }
        />
      </Box>
    </FormSection>
  );
}
