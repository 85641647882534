import { INITIAL_DATA_STATE } from '../store.constants';
import {
  partialCompanyQrAdapter,
  partialTalentPoolUserAdapter,
} from './connect.adapter';
import { ConnectState } from './connect.types';

export const initialConnectState: ConnectState = {
  createCompanyQr: INITIAL_DATA_STATE,
  companyQr: INITIAL_DATA_STATE,
  partialCompanyQrs: {
    ...INITIAL_DATA_STATE,
    data: partialCompanyQrAdapter.getInitialState(),
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'createdAt',
      sortOrder: 'desc',
    },
  },
  updateCompanyQr: INITIAL_DATA_STATE,

  talentPoolUser: INITIAL_DATA_STATE,
  talentPoolUserStats: INITIAL_DATA_STATE,
  partialTalentPoolUsers: {
    ...INITIAL_DATA_STATE,
    data: partialTalentPoolUserAdapter.getInitialState(),
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'createdAt',
      sortOrder: 'desc',
    },
  },
  updateUserNotes: INITIAL_DATA_STATE,
  toggleTalentPoolUserIsStarred: INITIAL_DATA_STATE,
};
