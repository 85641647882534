import { Time } from '@orbiapp/components';

import { PartialCompanyQr } from '../../models';
import {
  createCompanyQrThunk,
  getCompanyQrThunk,
  getPartialCompanyQrsThunk,
  getPartialTalentPoolUsersThunk,
  getTalentPoolUserStatsThunk,
  getTalentPoolUserThunk,
  toggleTalentPoolUserIsStarredThunk,
  updateCompanyQrThunk,
  updateUserNotesThunk,
} from './connect.actions';
import {
  partialCompanyQrAdapter,
  partialTalentPoolUserAdapter,
} from './connect.adapter';
import { ConnectBuilder } from './connect.types';

function createCompanyQrBuild(builder: ConnectBuilder) {
  return builder
    .addCase(createCompanyQrThunk.pending, (state) => {
      state.createCompanyQr.status = 'pending';
      state.createCompanyQr.error = null;
    })
    .addCase(createCompanyQrThunk.fulfilled, (state, action) => {
      const createdAt = Date.now();
      const endDate = action.meta.arg.startDate + 5 * Time.Day;

      state.createCompanyQr.status = 'idle';
      state.companyQr.data = {
        companyQrKey: action.payload.companyQrKey,
        createdAt,
        description: action.meta.arg.description,
        editMeta: action.payload.editMeta,
        endDate,
        name: action.meta.arg.name,
        scanCount: 0,
        link: null,
        startDate: action.meta.arg.startDate,
        connectCount: 0,
      };
      partialCompanyQrAdapter.addOne(state.partialCompanyQrs.data, {
        companyQrKey: action.payload.companyQrKey,
        createdAt,
        endDate,
        name: action.meta.arg.name,
        scanCount: 0,
        startDate: action.meta.arg.startDate,
        connectCount: 0,
      });
    })
    .addCase(createCompanyQrThunk.rejected, (state, action) => {
      state.createCompanyQr.status = 'idle';
      state.createCompanyQr.error = action.error;
    });
}

function getCompanyQrBuild(builder: ConnectBuilder) {
  return builder
    .addCase(getCompanyQrThunk.pending, (state) => {
      state.companyQr.status = 'pending';
      state.companyQr.error = null;
    })
    .addCase(getCompanyQrThunk.fulfilled, (state, action) => {
      state.talentPoolUser.data = null;

      state.companyQr.status = 'idle';
      state.companyQr.data = action.payload;
    })
    .addCase(getCompanyQrThunk.rejected, (state, action) => {
      state.companyQr.status = 'idle';
      state.companyQr.error = action.error;
    });
}

function getPartialCompanyQrsBuild(builder: ConnectBuilder) {
  return builder
    .addCase(getPartialCompanyQrsThunk.pending, (state, action) => {
      state.partialCompanyQrs.status = 'pending';
      state.partialCompanyQrs.error = null;
      state.partialCompanyQrs.pagination = action.meta.arg;
    })
    .addCase(getPartialCompanyQrsThunk.fulfilled, (state, action) => {
      state.partialCompanyQrs.status = 'idle';
      partialCompanyQrAdapter.setMany(
        state.partialCompanyQrs.data,
        action.payload,
      );
    })
    .addCase(getPartialCompanyQrsThunk.rejected, (state, action) => {
      state.partialCompanyQrs.status = 'idle';
      state.partialCompanyQrs.error = action.error;
    });
}

function updateCompanyQrBuild(builder: ConnectBuilder) {
  return builder
    .addCase(updateCompanyQrThunk.pending, (state) => {
      state.updateCompanyQr.status = 'pending';
      state.updateCompanyQr.error = null;
    })
    .addCase(updateCompanyQrThunk.fulfilled, (state, action) => {
      state.updateCompanyQr.status = 'idle';

      if (state.companyQr.data) {
        state.companyQr.data = {
          ...state.companyQr.data,
          description:
            action.meta.arg.description ?? state.companyQr.data.description,
          name: action.meta.arg.name ?? state.companyQr.data.name,
          startDate:
            action.meta.arg.startDate ?? state.companyQr.data.startDate,
          endDate: action.meta.arg.startDate
            ? action.meta.arg.startDate + 5 * Time.Day
            : state.companyQr.data.endDate,
        };

        const changes: Partial<PartialCompanyQr> = {
          startDate: action.meta.arg.startDate,
        };
        if (action.meta.arg.name) {
          changes.name = action.meta.arg.name;
        }
        if (action.meta.arg.startDate) {
          changes.startDate = action.meta.arg.startDate;
        }

        partialCompanyQrAdapter.updateOne(state.partialCompanyQrs.data, {
          id: state.companyQr.data.companyQrKey,
          changes,
        });
      }
    })
    .addCase(updateCompanyQrThunk.rejected, (state, action) => {
      state.updateCompanyQr.status = 'idle';
      state.updateCompanyQr.error = action.error;
    });
}

function getTalentPoolUserStatsBuild(builder: ConnectBuilder) {
  return builder
    .addCase(getTalentPoolUserStatsThunk.pending, (state) => {
      state.talentPoolUserStats.status = 'pending';
      state.talentPoolUserStats.error = null;
    })
    .addCase(getTalentPoolUserStatsThunk.fulfilled, (state, action) => {
      state.talentPoolUserStats.status = 'idle';
      state.talentPoolUserStats.data = action.payload;
    })
    .addCase(getTalentPoolUserStatsThunk.rejected, (state, action) => {
      state.talentPoolUserStats.status = 'idle';
      state.talentPoolUserStats.error = action.error;
    });
}

function getTalentPoolUserBuild(builder: ConnectBuilder) {
  return builder
    .addCase(getTalentPoolUserThunk.pending, (state) => {
      state.talentPoolUser.status = 'pending';
      state.talentPoolUser.error = null;
    })
    .addCase(getTalentPoolUserThunk.fulfilled, (state, action) => {
      state.companyQr.data = null;

      state.talentPoolUser.status = 'idle';
      state.talentPoolUser.data = action.payload;
    })
    .addCase(getTalentPoolUserThunk.rejected, (state, action) => {
      state.talentPoolUser.status = 'idle';
      state.talentPoolUser.error = action.error;
    });
}

function getPartialTalentPoolUsersBuild(builder: ConnectBuilder) {
  return builder
    .addCase(getPartialTalentPoolUsersThunk.pending, (state, action) => {
      state.partialTalentPoolUsers.status = 'pending';
      state.partialTalentPoolUsers.error = null;
      state.partialTalentPoolUsers.pagination = action.meta.arg;
    })
    .addCase(getPartialTalentPoolUsersThunk.fulfilled, (state, action) => {
      state.partialTalentPoolUsers.status = 'idle';

      partialTalentPoolUserAdapter.setMany(
        state.partialTalentPoolUsers.data,
        action.payload,
      );
    })
    .addCase(getPartialTalentPoolUsersThunk.rejected, (state, action) => {
      state.partialTalentPoolUsers.status = 'idle';
      state.partialTalentPoolUsers.error = action.error;
    });
}

function updateUserNotesBuild(builder: ConnectBuilder) {
  return builder
    .addCase(updateUserNotesThunk.pending, (state) => {
      state.updateUserNotes.status = 'pending';
      state.updateUserNotes.error = null;
    })
    .addCase(updateUserNotesThunk.fulfilled, (state, action) => {
      state.updateUserNotes.status = 'idle';

      if (state.talentPoolUser.data) {
        state.talentPoolUser.data.notes = action.meta.arg.notes;
      }
    })
    .addCase(updateUserNotesThunk.rejected, (state, action) => {
      state.updateUserNotes.status = 'idle';
      state.updateUserNotes.error = action.error;
    });
}

function toggleTalentPoolUserIsStarredBuild(builder: ConnectBuilder) {
  return builder
    .addCase(toggleTalentPoolUserIsStarredThunk.pending, (state) => {
      state.toggleTalentPoolUserIsStarred.status = 'pending';
      state.toggleTalentPoolUserIsStarred.error = null;
    })
    .addCase(toggleTalentPoolUserIsStarredThunk.fulfilled, (state) => {
      state.toggleTalentPoolUserIsStarred.status = 'idle';
    })
    .addCase(toggleTalentPoolUserIsStarredThunk.rejected, (state, action) => {
      state.toggleTalentPoolUserIsStarred.status = 'idle';
      state.toggleTalentPoolUserIsStarred.error = action.error;
    });
}

export function extraReducers(builder: ConnectBuilder) {
  createCompanyQrBuild(builder);
  getCompanyQrBuild(builder);
  getPartialCompanyQrsBuild(builder);
  updateCompanyQrBuild(builder);

  getTalentPoolUserStatsBuild(builder);
  getTalentPoolUserBuild(builder);
  getPartialTalentPoolUsersBuild(builder);
  updateUserNotesBuild(builder);
  toggleTalentPoolUserIsStarredBuild(builder);
}
