import { GeneralApiResultWithData } from '@orbiapp/components';

import { CreateCompanyParams } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { createCompanyParamsMapper } from './company.mappers';

export const createCompany = async (
  createCompanyParams: CreateCompanyParams,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ companyKey: string }, any>(
    '/v1/company/by-invitation',
    createCompanyParamsMapper(createCompanyParams),
  );

  if (!res.ok) {
    Logger.warning('createCompany', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.companyKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createCompany', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
