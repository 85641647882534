import {
  fromScu,
  getFileFromUrl,
  isUndefined,
  toScu,
} from '@orbiapp/components';

import {
  CreateJobForm,
  Job,
  PartialJob,
  UpdateJobForm,
} from '../../../../../models';
import { getJobBudgetType } from '../../../../../utils';
import { CreateJobDto, JobDto, PartialJobDto, UpdateJobDto } from './jobs.dto';

export function createJobMapper(createJobForm: CreateJobForm): CreateJobDto {
  return {
    contactEmail: createJobForm.contactEmail,
    contactName: createJobForm.contactName,
    contactPhone: createJobForm.contactPhone?.length
      ? createJobForm.contactPhone
      : null,
    coverImage: createJobForm.coverImage!,
    budgetData: {
      dailyBudget: createJobForm.budgetData.dailyBudget
        ? toScu(createJobForm.budgetData.dailyBudget)
        : null,
      totalBudget: createJobForm.budgetData.totalBudget
        ? toScu(createJobForm.budgetData.totalBudget)
        : null,
    },
    description: createJobForm.description,
    employmentType: createJobForm.employmentType,
    endDate: createJobForm.endDate,
    jobDraftKey: createJobForm.jobDraftKey,

    originalJobUrl: createJobForm.originalJobUrl?.length
      ? createJobForm.originalJobUrl
      : null,
    qualifications: createJobForm.qualifications?.length
      ? createJobForm.qualifications
      : null,
    startDate: createJobForm.startDate,
    studyLevels: createJobForm.studyLevels,
    subjectAreaKeys: createJobForm.subjectAreaKeys,
    title: createJobForm.title,

    locationData: {
      workModel: createJobForm.locationData.workModel,
      officeLocations:
        createJobForm.locationData.officeLocations
          ?.map((officeLocation) => ({
            index: officeLocation.index,
            officeLocationKey: officeLocation.officeLocationKey,
          }))
          .sort((a, b) => a.index - b.index)
          .map((officeLocation, index) => ({
            index,
            officeLocationKey: officeLocation.officeLocationKey,
          })) ?? null,
    },
  };
}

export async function jobMapper(jobDto: JobDto): Promise<Job> {
  const coverImageBase64 = jobDto.coverImage?.original.url
    ? await getFileFromUrl(jobDto.coverImage.original.url)
    : null;

  return {
    budgetData: {
      dailyBudget: jobDto.budgetData.dailyBudget
        ? fromScu(jobDto.budgetData.dailyBudget)
        : null,
      totalBudget: jobDto.budgetData.totalBudget
        ? fromScu(jobDto.budgetData.totalBudget)
        : null,
      budgetType: getJobBudgetType(jobDto.budgetData),
    },
    contactEmail: jobDto.contactEmail,
    contactName: jobDto.contactName,
    contactPhone: jobDto.contactPhone,
    coverImage: jobDto.coverImage,
    coverImageBase64,
    dailyBudgetReachedAt: jobDto.dailyBudgetReachedAt,
    description: jobDto.description,
    editMeta: jobDto.editMeta,
    employmentType: jobDto.employmentType,
    endDate: jobDto.endDate,
    jobKey: jobDto.jobKey,
    locationData: jobDto.locationData,
    originalJobUrl: jobDto.originalJobUrl,
    qualifications: jobDto.qualifications,
    startDate: jobDto.startDate,
    studyLevels: jobDto.studyLevels,
    subjectAreas: jobDto.subjectAreas,
    title: jobDto.title,
    totalBudgetReachedAt: jobDto.totalBudgetReachedAt,
    isManuallyClosed: jobDto.isManuallyClosed,
    stats: {
      clickCount: jobDto.stats.clickCount,
      totalBudgetSpend: fromScu(jobDto.stats.totalBudgetSpend),
    },
  };
}

export function partialJobMapper(partialJobDto: PartialJobDto): PartialJob {
  return {
    isManuallyClosed: partialJobDto.isManuallyClosed,
    dailyBudgetReachedAt: partialJobDto.dailyBudgetReachedAt,
    editMeta: partialJobDto.editMeta,
    employmentType: partialJobDto.employmentType,
    endDate: partialJobDto.endDate,
    jobKey: partialJobDto.jobKey,
    startDate: partialJobDto.startDate,
    title: partialJobDto.title,
    totalBudgetReachedAt: partialJobDto.totalBudgetReachedAt,
  };
}

export function updateJobMapper(jobForm: UpdateJobForm): UpdateJobDto {
  const updateJobDto: UpdateJobDto = {};

  if (!isUndefined(jobForm.contactEmail)) {
    updateJobDto.contactEmail = jobForm.contactEmail;
  }
  if (!isUndefined(jobForm.contactName)) {
    updateJobDto.contactName = jobForm.contactName;
  }
  if (!isUndefined(jobForm.contactPhone)) {
    updateJobDto.contactPhone = jobForm.contactPhone || null;
  }
  if (!isUndefined(jobForm.coverImage)) {
    updateJobDto.coverImage = jobForm.coverImage!;
  }
  if (!isUndefined(jobForm.description)) {
    updateJobDto.description = jobForm.description;
  }
  if (!isUndefined(jobForm.employmentType)) {
    updateJobDto.employmentType = jobForm.employmentType;
  }
  if (!isUndefined(jobForm.endDate)) {
    updateJobDto.endDate = jobForm.endDate;
  }
  if (!isUndefined(jobForm.locationData)) {
    updateJobDto.locationData = {
      workModel: jobForm.locationData.workModel,
      officeLocations: jobForm.locationData.officeLocations?.length
        ? jobForm.locationData.officeLocations
            ?.map((officeLocation) => ({
              index: officeLocation.index,
              officeLocationKey: officeLocation.officeLocationKey,
            }))
            .sort((a, b) => a.index - b.index)
            .map((officeLocation, index) => ({
              index,
              officeLocationKey: officeLocation.officeLocationKey,
            })) ?? null
        : null,
    };
  }
  if (!isUndefined(jobForm.originalJobUrl)) {
    updateJobDto.originalJobUrl = jobForm.originalJobUrl || null;
  }
  if (!isUndefined(jobForm.qualifications)) {
    updateJobDto.qualifications = jobForm.qualifications;
  }
  if (!isUndefined(jobForm.startDate)) {
    updateJobDto.startDate = jobForm.startDate;
  }
  if (!isUndefined(jobForm.studyLevels)) {
    updateJobDto.studyLevels = jobForm.studyLevels;
  }
  if (!isUndefined(jobForm.subjectAreaKeys)) {
    updateJobDto.subjectAreaKeys = jobForm.subjectAreaKeys;
  }
  if (!isUndefined(jobForm.title)) {
    updateJobDto.title = jobForm.title;
  }
  if (!isUndefined(jobForm.budgetData)) {
    updateJobDto.budgetData = {
      totalBudget: jobForm.budgetData.totalBudget
        ? toScu(jobForm.budgetData.totalBudget)
        : null,
      dailyBudget: jobForm.budgetData.dailyBudget
        ? toScu(jobForm.budgetData.dailyBudget)
        : null,
    };
  }

  return updateJobDto;
}
