import Joi from 'joi';

import { CreateJobSchema } from '../job/job.schema';

export namespace UpdateJobDraftSchema {
  export const contactEmail = CreateJobSchema.contactEmail.allow(null);
  export const contactPhone = CreateJobSchema.contactPhone.allow(null);
  export const coverImage = CreateJobSchema.coverImage.allow(null);
  export const contactName = CreateJobSchema.contactName.allow(null);
  export const description = CreateJobSchema.description.allow(null);
  export const employmentType = CreateJobSchema.employmentType.allow(null);
  export const jobDraftKey = CreateJobSchema.jobDraftKey.allow(null);
  export const originalJobUrl = CreateJobSchema.originalJobUrl.allow(null);
  export const qualifications = CreateJobSchema.qualifications.allow(null);
  export const studyLevels = CreateJobSchema.studyLevels.allow(null);
  export const subjectAreaKeys = CreateJobSchema.subjectAreaKeys.allow(null);
  export const title = CreateJobSchema.title.allow(null);
  export const budgetData = CreateJobSchema.budgetData.allow(null);
  export const locationData = CreateJobSchema.locationData;
  export const endDate = CreateJobSchema.endDate.allow(null);

  export const startDate = Joi.number().required();
}
