import {
  EditMeta,
  EditedByMeta,
  Pagination,
  formatPublishDate,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateOfferForm,
  Offer,
  OfferCategory,
  OfferDraft,
  OffersOrderByKey,
  PartialOffer,
  PartialOfferDraft,
  UpdateOfferForm,
} from '../../models';
import {
  OrbiApi,
  createDynamicLink,
  getPageAndNextPage,
  v1,
} from '../../services';
import { getDuplicateOfferPayload, getOfferEditMeta } from '../../utils';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { partialOffersAdapter } from './offers.adapter';

export const createOfferDraftThunk = createAsyncThunk<
  { offerDraftKey: string; editMeta: EditMeta },
  undefined,
  ThunkApiConfig
>('offers/drafts/create-draft', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.offers.drafts.createDraft, undefined);

  if (res.kind === 'ok') {
    return {
      offerDraftKey: res.data,
      editMeta: getOfferEditMeta(state.account.account.data),
    };
  }

  return thunkAPI.rejectWithValue(res);
});

export const deleteOfferDraftThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('offers/drafts/delete-draft', async (offerDraftKey, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.drafts.deleteDraft, offerDraftKey);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-offer-draft:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('delete-offer-draft:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateOfferDraftThunk = createAsyncThunk<
  void,
  { offerDraftKey: string; createOfferForm: CreateOfferForm },
  ThunkApiConfig
>('offers/drafts/update-draft', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.drafts.updateDraft, {
    offerDraftKey: params.offerDraftKey,
    updateOfferDraft: params.createOfferForm,
  });

  if (res.kind === 'ok') {
    return;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getOfferDraftsThunk = createAsyncThunk<
  PartialOfferDraft[],
  undefined,
  ThunkApiConfig
>('offers/drafts/get-drafts', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.drafts.getDrafts, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getOfferDraftThunk = createAsyncThunk<
  OfferDraft,
  string,
  ThunkApiConfig
>('offers/drafts/get-draft', async (offerDraftKey, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.drafts.getDraft, offerDraftKey);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createOfferThunk = createAsyncThunk<
  PartialOffer,
  CreateOfferForm,
  ThunkApiConfig
>('offers/create-offer', async (createOfferForm, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.offers.createOffer, createOfferForm);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert('publish-offer:success', {
        publishDate: formatPublishDate(createOfferForm.startDate).toLowerCase(),
      }),
    );

    const editedByMeta: EditedByMeta = {
      firstName: state.account.account.data.firstName,
      lastName: state.account.account.data.lastName,
      profilePicture: state.account.account.data.profilePicture,
      userKey: state.account.account.data.userKey,
    };
    const now = Date.now();
    const offer: PartialOffer = {
      contactName: createOfferForm.contactName,
      disabledAt: null,
      editMeta: {
        createdAt: now,
        createdBy: editedByMeta,
        updatedAt: now,
        updatedBy: editedByMeta,
      },
      endDate: createOfferForm.endDate,
      offerKey: res.data.offerKey,
      startDate: createOfferForm.startDate,
      title: createOfferForm.title,
    };
    return offer;
  }

  thunkAPI.dispatch(setAlert('publish-offer:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateOfferThunk = createAsyncThunk<
  PartialOffer,
  UpdateOfferForm,
  ThunkApiConfig
>('offers/update-offer', async (updateOfferForm, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.offers.offer.data || !state.account.account.data) {
    thunkAPI.dispatch(setAlert('update-offer:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.offers.updateOffer, {
    updateOffer: updateOfferForm,
    offerKey: state.offers.offer.data.offerKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-offer:success'));

    return {
      contactName: updateOfferForm.contactName,
      disabledAt: null,
      editMeta: getOfferEditMeta(state.account.account.data),
      endDate: updateOfferForm.endDate,
      offerKey: state.offers.offer.data.offerKey,
      startDate: updateOfferForm.startDate,
      title: updateOfferForm.title,
    };
  }

  thunkAPI.dispatch(setAlert('update-offer:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getOffersThunk = createAsyncThunk<
  PartialOffer[],
  Pagination<OffersOrderByKey>,
  ThunkApiConfig
>('offers/get-offers', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const search = state.offers.offers.search;
  if (search) {
    const res = await OrbiApi.call(v1.offers.getOffers, { pagination, search });

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialOffersAdapter.getSelectors().selectAll(state.offers.offers.data),
    v1.offers.getOffers,
    { pagination, search: undefined },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getOfferThunk = createAsyncThunk<Offer, string, ThunkApiConfig>(
  'offers/get-offer',
  async (offerKey, thunkAPI) => {
    const res = await OrbiApi.call(v1.offers.getOffer, offerKey);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const duplicateOfferThunk = createAsyncThunk<
  { offerDraftKey: string; editMeta: EditMeta },
  string,
  ThunkApiConfig
>('offers/duplicate-offer', async (offerKey, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.account.account.data) {
    thunkAPI.dispatch(setAlert('duplicate-offer:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const offerRes = await OrbiApi.call(v1.offers.getOffer, offerKey);
  if (offerRes.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('duplicate-offer:error'));
    return thunkAPI.rejectWithValue(offerRes);
  }

  const createDraftRes = await OrbiApi.call(
    v1.offers.drafts.createDraft,
    undefined,
  );

  if (createDraftRes.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('duplicate-offer:error'));
    return thunkAPI.rejectWithValue(createDraftRes);
  }

  const updateOfferDraft = await getDuplicateOfferPayload(offerRes.data);

  const updateDraftRes = await OrbiApi.call(v1.offers.drafts.updateDraft, {
    offerDraftKey: createDraftRes.data,
    updateOfferDraft,
  });

  if (updateDraftRes.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('duplicate-offer:error'));

    await OrbiApi.call(v1.offers.drafts.deleteDraft, createDraftRes.data);
    return thunkAPI.rejectWithValue(updateDraftRes);
  }

  thunkAPI.dispatch(setAlert('duplicate-offer:success'));

  return {
    offerDraftKey: createDraftRes.data,

    editMeta: getOfferEditMeta(state.account.account.data),
  };
});

export const getOfferCategoriesThunk = createAsyncThunk<
  OfferCategory[],
  undefined,
  ThunkApiConfig
>('offers/get-offer-categories', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.getOfferCategories, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const toggleIsDisabledThunk = createAsyncThunk<
  {
    disabledAt: number | null;
    editMeta: EditMeta;
    offerKey: string;
  },
  string,
  ThunkApiConfig
>('offers/toggle-is-disabled', async (offerKey, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const offer =
    state.offers.offer.data ?? state.offers.offers.data.entities[offerKey];

  const isDisabled = !Boolean(offer.disabledAt);

  const res = await OrbiApi.call(v1.offers.toggleIsDisabled, {
    offerKey,
    isDisabled,
  });

  if (res.kind === 'ok') {
    const editMeta: EditMeta = {
      updatedBy: {
        firstName: state.account.account.data.firstName,
        lastName: state.account.account.data.lastName,
        profilePicture: state.account.account.data.profilePicture,
        userKey: state.account.account.data.userKey,
      },
      updatedAt: Date.now(),
      createdAt: offer.editMeta.createdAt,
      createdBy: offer.editMeta.createdBy,
    };

    return {
      editMeta,
      disabledAt: isDisabled ? editMeta.updatedAt : null,
      offerKey,
    };
  }

  return thunkAPI.rejectWithValue(res);
});

export const getOffersLinkThunk = createAsyncThunk<
  string,
  undefined,
  ThunkApiConfig
>('offers/get-offers-link', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.company.company.data?.companyKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  if (state.offers.offersLink.data) {
    return state.offers.offersLink.data;
  }

  const res = await createDynamicLink({
    ownerType: 'company',
    key: state.company.company.data.companyKey,
    page: 'offers',
  });
  if (!res.data.shortLink) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  return res.data.shortLink;
});
