import dayjs from 'dayjs';

import { GetPredefinedInsightsPoints } from './insights.types';

function getToday(dayjs: dayjs.Dayjs) {
  const startOfDay = dayjs.startOf('day');
  const endOfDay = dayjs.add(1, 'day').startOf('day');
  const diffHours = endOfDay.diff(startOfDay, 'hours');

  const today = [];

  for (let i = 1; i < diffHours; i++) {
    today.push(startOfDay.add(i, 'hour').valueOf());
  }

  return [
    startOfDay.valueOf(),
    ...today,
    endOfDay.valueOf(),
    endOfDay.add(1, 'hour').startOf('hour').valueOf(),
  ];
}

function getLast7Days(dayjs: dayjs.Dayjs) {
  const last7Days = [];

  const endOf7Days = dayjs.add(1, 'day').startOf('day');
  const startOf7Days = dayjs.subtract(7, 'day').startOf('day');
  const diffDays = endOf7Days.diff(startOf7Days, 'days');

  for (let i = 0; i < diffDays; i++) {
    last7Days.push(startOf7Days.add(i, 'day').valueOf());
  }

  return [...last7Days, endOf7Days.valueOf()];
}

function getLast30Days(dayjs: dayjs.Dayjs) {
  const last30Days = [];

  const endOf30Days = dayjs.add(1, 'day').startOf('day');
  const startOf30Days = dayjs.subtract(29, 'day').startOf('day');
  const diffDays30 = endOf30Days.diff(startOf30Days, 'days');

  for (let i = 0; i < diffDays30; i++) {
    last30Days.push(startOf30Days.add(i, 'day').valueOf());
  }

  return [...last30Days, endOf30Days.valueOf()];
}

function getLast90Days(dayjs: dayjs.Dayjs) {
  const last90Days = [];

  const endOf90Days = dayjs.add(1, 'day').startOf('day');
  const startOf90Days = dayjs.subtract(89, 'day').startOf('day');
  const diffDays90 = endOf90Days.diff(startOf90Days, 'days');

  for (let i = 0; i < diffDays90; i++) {
    last90Days.push(startOf90Days.add(i, 'day').valueOf());
  }

  return [...last90Days, endOf90Days.valueOf()];
}

function getLast365Days(dayjs: dayjs.Dayjs) {
  const last365Days = [];

  const endOf365Days = dayjs.add(1, 'day').startOf('day');
  const startOf365Days = dayjs.subtract(364, 'day').startOf('day');
  const diffDays365 = endOf365Days.diff(startOf365Days, 'days');

  for (let i = 0; i < diffDays365; i++) {
    last365Days.push(startOf365Days.add(i, 'day').valueOf());
  }

  return [...last365Days, endOf365Days.valueOf()];
}

function getPreviousMonth(dayjs: dayjs.Dayjs) {
  const startOfPreviousMonth = dayjs.subtract(1, 'month').startOf('month');
  const endOfPreviousMonth = dayjs.startOf('month');

  const diffDaysPreviousMonth = endOfPreviousMonth.diff(
    startOfPreviousMonth,
    'days',
  );

  const previousMonth = [];

  for (let i = 0; i < diffDaysPreviousMonth; i++) {
    previousMonth.push(startOfPreviousMonth.add(i, 'day').valueOf());
  }

  return [...previousMonth, endOfPreviousMonth.valueOf()];
}

function getPreviousQuarter(dayjs: dayjs.Dayjs) {
  const startOfPreviousQuarter = dayjs
    .subtract(1, 'quarter')
    .startOf('quarter');
  const endOfPreviousQuarter = dayjs.startOf('quarter');

  const diffDaysPreviousQuarter = endOfPreviousQuarter.diff(
    startOfPreviousQuarter,
    'days',
  );

  const previousQuarter = [];

  for (let i = 0; i < diffDaysPreviousQuarter; i++) {
    previousQuarter.push(startOfPreviousQuarter.add(i, 'day').valueOf());
  }

  return [...previousQuarter, endOfPreviousQuarter.valueOf()];
}

function getPreviousYear(dayjs: dayjs.Dayjs) {
  const startOfPreviousYear = dayjs.subtract(1, 'year').startOf('year');
  const endOfPreviousYear = dayjs.startOf('year');

  const diffDaysPreviousYear = endOfPreviousYear.diff(
    startOfPreviousYear,
    'months',
  );

  const previousYear = [];

  for (let i = 0; i < diffDaysPreviousYear; i++) {
    previousYear.push(startOfPreviousYear.add(i, 'month').valueOf());
  }

  return [...previousYear, endOfPreviousYear.valueOf()];
}

const getInsightsDatesMap = {
  today: getToday,
  last_7_days: getLast7Days,
  last_30_days: getLast30Days,
  last_90_days: getLast90Days,
  last_365_days: getLast365Days,
  previous_month: getPreviousMonth,
  previous_quarter: getPreviousQuarter,
  previous_year: getPreviousYear,
} as const;

export function getInsightDates(params: GetPredefinedInsightsPoints): number[] {
  return getInsightsDatesMap[params.interval](params.dayjs);
}
