import {
  GeneralApiResult,
  GeneralApiResultWithData,
  RequestVerifyEmailParams,
} from '@orbiapp/components';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

import { AUTH_URL } from '../../../../../config';
import { RequestResetPasswordEmailErrorResponse } from '../../../../../models';
import { firebase } from '../../../../firebase';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';

export const createResetPassword = async (params: {
  redirectUrl: string;
  email: string;
}): Promise<GeneralApiResult<RequestResetPasswordEmailErrorResponse>> => {
  const res = await apisauce.post<
    undefined,
    RequestResetPasswordEmailErrorResponse
  >('/v1/reset-password', params, { baseURL: AUTH_URL });

  if (!res.ok) {
    Logger.warning('createResetPassword', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const sendEmailVerification = async (
  params: RequestVerifyEmailParams,
): Promise<GeneralApiResult> => {
  const res = await apisauce.post<undefined, any>(
    '/v1/verify-email-address',
    params,
    {
      baseURL: AUTH_URL,
    },
  );

  if (!res.ok) {
    Logger.warning('sendEmailVerification', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getCustomSignInToken = async (): Promise<
  GeneralApiResultWithData<string>
> => {
  const token = await getAuth(firebase).currentUser?.getIdToken();

  const res = await axios.get<{ token: string }, any>('/v1/token', {
    baseURL: AUTH_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data.token };
  } catch (err) {
    Logger.error('getCustomSignInToken', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
