import {
  Box,
  Checkbox,
  ControlledSelect,
  DangerIconButton,
  DragDrop,
  DragDropItem,
  DragDropItemHandle,
  FormSection,
  FormSectionHeader,
  FormSectionHeaderRow,
  GetSelectOverrides,
  Link,
  OptionProps,
  SolidIconButton,
  Status,
  Text,
  Tooltip,
  isTxString,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CompanyProfileForm,
  CreateIndexedOfficeLocation,
  WorkModel,
} from '../../../../../models';
import { OfficeLocationsSelector, useSelector } from '../../../../../store';
import { getWorkModelTx } from '../../../../../utils';
import { SaveFieldOnClickOutside } from '../save-field-on-click-outside';
import { ProfileContext } from './profile-form.context';

const getOverrides: GetSelectOverrides = (selectedOption) => ({
  label:
    selectedOption?.text?.toString() ??
    translate('label.profile.locations.inputs.location'),
  value:
    selectedOption?.subtitle ??
    translate('label.profile.locations.inputs.select-location'),
});

function WorkModels(props: {
  officeLocations: CreateIndexedOfficeLocation[];
  workModels: WorkModel[];
}) {
  const { officeLocations, workModels } = props;

  const { setValue } = useFormContext<CompanyProfileForm>();

  const handleCheckBoxClick = (workModel: WorkModel) => () => {
    const _workModels = workModels.includes(workModel)
      ? workModels.filter((_workModel) => _workModel !== workModel)
      : [...workModels, workModel];

    if (_workModels.length === 0 && officeLocations.length === 0) {
      setValue('locationData', null, {
        shouldDirty: true,
      });

      return;
    }

    setValue(
      'locationData',
      { officeLocations, workModels: _workModels },
      {
        shouldDirty: true,
      },
    );
  };

  return (
    <Box gap={16} flexAlign="start" flex>
      <Checkbox
        checked={workModels.includes('onsite')}
        onChange={handleCheckBoxClick('onsite')}
        tx={getWorkModelTx('onsite')}
      />
      <Checkbox
        checked={workModels.includes('hybrid')}
        onChange={handleCheckBoxClick('hybrid')}
        tx={getWorkModelTx('hybrid')}
      />
      <Checkbox
        checked={workModels.includes('remote')}
        onChange={handleCheckBoxClick('remote')}
        tx={getWorkModelTx('remote')}
        subtitleTx="label.profile.locations.inputs.optional"
      />
    </Box>
  );
}

function LocationListItem(props: {
  isAlone: boolean;
  officeLocation: CreateIndexedOfficeLocation;
  removeLocation: () => void;
  unSelectedOptions: OptionProps[];
}) {
  const { officeLocation, removeLocation, unSelectedOptions } = props;

  const selectedLocation = useSelector((state) =>
    OfficeLocationsSelector.selectOfficeLocationById(
      state,
      officeLocation.officeLocationKey,
    ),
  );

  const options: OptionProps[] = selectedLocation
    ? unSelectedOptions
        .concat({
          text: selectedLocation.label,
          value: selectedLocation.officeLocationKey,
          icon: 'map-pin-outline',
          subtitle: selectedLocation.address,
        })
        .sort((a, b) => `${a.text}`.localeCompare(`${b.text}`, 'sv'))
    : unSelectedOptions.length
    ? unSelectedOptions
    : [
        {
          notPickable: true,
          tx: 'placeholder.no-locations',
          value: ' ',
        },
      ];

  return (
    <DragDropItem id={`${officeLocation.index}`} flex gap={8} minHeight={88}>
      <Tooltip placement="top" titleTx="label.tooltip.move">
        <Box height={58} flex flexAlign="center">
          <DragDropItemHandle />
        </Box>
      </Tooltip>

      <ControlledSelect
        getOverrides={getOverrides}
        name={`locationData.officeLocations.${officeLocation.index}.officeLocationKey`}
        options={options}
        width="100%"
      />

      <Tooltip placement="top" titleTx="label.tooltip.delete">
        <Box height={58} flex flexAlign="center">
          <DangerIconButton
            icon="minus-circle-outline"
            onClick={removeLocation}
          />
        </Box>
      </Tooltip>
    </DragDropItem>
  );
}

function LocationItems(props: {
  officeLocations: CreateIndexedOfficeLocation[];
  unSelectedOptions: OptionProps[];
  workModels: WorkModel[];
}) {
  const { officeLocations, unSelectedOptions, workModels } = props;

  const { formMode, saveField } = React.useContext(ProfileContext);

  const { setValue } = useFormContext<CompanyProfileForm>();
  const isDraft = formMode === 'createProfile';

  const [items, setItems] = React.useState<
    ({ id: string } & CreateIndexedOfficeLocation)[]
  >([]);

  const handleChange = (newState: typeof items) => {
    setItems(newState);

    setValue(
      'locationData.officeLocations',
      newState.map((location, index) => ({
        index,
        officeLocationKey: location.officeLocationKey,
      })),
      {
        shouldDirty: true,
      },
    );

    if (isDraft) {
      saveField('locationData');
    }
  };

  React.useEffect(() => {
    setItems(
      officeLocations.map((officeLocation) => ({
        ...officeLocation,
        id: `${officeLocation.index}`,
      })) ?? [],
    );
  }, [officeLocations]);

  const renderLocation = (officeLocation: CreateIndexedOfficeLocation) => {
    const removeLocation = () => {
      const _officeLocations = officeLocations.filter(
        (location) => location.index !== officeLocation.index,
      );

      if (_officeLocations.length === 0 && workModels.length === 0) {
        setValue('locationData', null, { shouldDirty: true });

        return;
      }

      setValue(
        'locationData',
        {
          officeLocations:
            _officeLocations.length === 0
              ? null
              : _officeLocations.map((location, index) => ({
                  ...location,
                  index,
                })),
          workModels,
        },
        {
          shouldDirty: true,
        },
      );
    };

    return (
      <LocationListItem
        isAlone={officeLocations.length === 1}
        officeLocation={officeLocation}
        key={`location-item-${officeLocation.index}`}
        removeLocation={removeLocation}
        unSelectedOptions={unSelectedOptions}
      />
    );
  };

  return (
    <DragDrop items={items} onChange={handleChange}>
      {officeLocations.map(renderLocation)}
    </DragDrop>
  );
}

export function LocationData() {
  const allLocations = useSelector(
    OfficeLocationsSelector.selectOfficeLocations,
  );

  const { formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<CompanyProfileForm>();

  const locationData = watch('locationData');
  const officeLocations = locationData?.officeLocations ?? [];
  const workModels = locationData?.workModels ?? [];

  const locationKeys = new Set(
    officeLocations.map((location) => location.officeLocationKey),
  );

  const unSelectedLocations = locationData
    ? allLocations.filter(
        (location) => !locationKeys.has(location.officeLocationKey),
      )
    : allLocations;

  const unSelectedOptions = unSelectedLocations
    .map(
      (companyLocation): OptionProps => ({
        text: companyLocation.label,
        value: companyLocation.officeLocationKey,
        icon: 'map-pin-outline',
        subtitle: companyLocation.address,
      }),
    )
    .sort((a, b) => `${a.text}`.localeCompare(`${b.text}`, 'sv'));

  const addLocation = () => {
    const _officeLocations = [
      ...officeLocations,
      { index: officeLocations.length, officeLocationKey: '' },
    ];

    setValue(
      'locationData',
      { officeLocations: _officeLocations, workModels },
      {
        shouldDirty: true,
      },
    );
  };

  const errorMessage =
    errors.locationData?.message ??
    errors.locationData?.root?.message ??
    errors.locationData?.officeLocations?.message ??
    errors.locationData?.officeLocations?.root?.message ??
    errors.locationData?.workModels?.message ??
    errors.locationData?.workModels?.root?.message;

  const addIsDisabled = officeLocations.length >= allLocations.length;

  return (
    <SaveFieldOnClickOutside field="locationData" disabled={!isDraft}>
      <FormSection>
        <FormSectionHeaderRow>
          <FormSectionHeader>
            <Text
              tx="label.profile.locations.header"
              variant="bodyMdBold"
              color="formSectionHeader"
            />

            <Box flexWrap gap={4} flex>
              <Text
                tx="label.profile.locations.description"
                variant="bodyMd"
                color="formSectionDescription"
              />
              <Link
                to="/settings/company"
                tx="label.profile.locations.description-link"
                variant="secondary"
              />
            </Box>
          </FormSectionHeader>

          <Status
            ml="auto"
            variant="warning"
            tx="label.profile.locations.counter-status"
            txArgs={{
              length: officeLocations.length,
              max: allLocations.length,
            }}
          />

          <Tooltip
            placement="top"
            titleTx="label.tooltip.add"
            disabled={addIsDisabled}
          >
            <SolidIconButton
              onClick={addLocation}
              icon="plus-circle-outline"
              disabled={addIsDisabled}
            />
          </Tooltip>
        </FormSectionHeaderRow>

        {isTxString(errorMessage) && (
          <Text my={4} tx={errorMessage} variant="bodyMd" color="errorLabel" />
        )}

        <WorkModels officeLocations={officeLocations} workModels={workModels} />

        <Box flex flexDirection="column" gap={8}>
          <LocationItems
            officeLocations={officeLocations}
            unSelectedOptions={unSelectedOptions}
            workModels={workModels}
          />
        </Box>
      </FormSection>
    </SaveFieldOnClickOutside>
  );
}
