import {
  Box,
  ControlledDatePicker,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  IconButton,
  InputChip,
  InputChips,
  Text,
  Tooltip,
  TrailingInputBox,
  WyiswygEditContainer,
  isTxString,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  JOB_ORIGINAL_URL_MAX_LENGTH,
  JOB_QUALIFICATIONS_MAX_COUNT,
  JOB_QUALIFICATION_MAX_LENGTH,
  UpdateJobForm,
} from '../../../../../models';
import { JobSelector, useSelector } from '../../../../../store';
import { getOptionalLabelText } from '../../../../../utils';

function QualificationsFormSection() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  const formContext = useFormContext<UpdateJobForm>();

  const qualifications = formContext.watch('qualifications');

  const [qualification, setQualification] = React.useState('');

  const handleAddChip = (value: string) => {
    if (qualifications?.includes(value)) return;

    formContext.setValue(
      'qualifications',
      (qualifications ?? []).concat(value),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
    setQualification('');
  };

  const handleRemoveChip = (id: number) => {
    formContext.setValue(
      'qualifications',
      qualifications?.filter((_, index) => index !== id) ?? [],
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
  };

  const handleInputChipValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setQualification(e.target.value);
  };

  const chips: InputChip<number>[] = (qualifications ?? []).map(
    (qualification, index) => ({
      id: index,
      text: qualification,
      maxWidth: '65ch',
    }),
  );

  const handlePlusClick = () => {
    if (!qualification) return;

    handleAddChip(qualification);
  };

  return (
    <WyiswygEditContainer>
      <InputChips
        disabled={!canEditJob}
        value={qualification}
        chips={chips}
        showInput={!!qualification}
        label={getOptionalLabelText('label.job-form.qualifications')}
        errorTx={
          qualifications?.includes(qualification)
            ? 'errors.qualification.qualification-already-exists'
            : isTxString(formContext.formState.errors.qualifications?.message)
            ? formContext.formState.errors.qualifications?.message
            : undefined
        }
        maxChips={JOB_QUALIFICATIONS_MAX_COUNT}
        maxLength={JOB_QUALIFICATION_MAX_LENGTH}
        onAddChip={handleAddChip}
        onRemoveChip={handleRemoveChip}
        onChange={handleInputChipValueChange}
        trailingElement={
          !canEditJob ? undefined : (
            <TrailingInputBox>
              <Tooltip placement="top" titleTx="label.tooltip.add">
                <IconButton
                  disabled={chips.length >= JOB_QUALIFICATIONS_MAX_COUNT}
                  icon="plus-circle-outline"
                  onClick={handlePlusClick}
                />
              </Tooltip>
            </TrailingInputBox>
          )
        }
      />
    </WyiswygEditContainer>
  );
}

export function AdditionalInformation() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.2.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.2.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <ControlledDatePicker
          name="endDate"
          type="datetime-local"
          labelTx="label.job-form.last-application-date"
          min={parseTimestamp(Date.now(), 'YYYY-MM-DDTHH:mm')}
          disabled={!canEditJob}
        />

        <ControlledInput
          label={getOptionalLabelText('label.job-form.original-job-url')}
          name="originalJobUrl"
          maxLength={JOB_ORIGINAL_URL_MAX_LENGTH}
          disabled={!canEditJob}
        />

        <QualificationsFormSection />
      </Box>
    </FormSection>
  );
}
